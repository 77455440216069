<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-05-31 13:27:39
-->
<template>
  <div class="container-card" :class="{ 'is-plain': isPlain }">
    <Banner
      v-if="isPlain && title"
      :is-plain="true"
      :is-btn="false"
      :title="title"
      :bg-url="bgUrl"
    />
    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Banner from './Banner.vue'

export default {
  name: 'ContainerCard',
  components: { Banner },
  props: {
    isPlain: Boolean,
    title: String,
    bgUrl: String
  }
}
</script>

<style lang="less" scoped>
.container-card {
  background-color: @bg-color;
  padding: 24px 0 0;
  &.is-plain {
    padding: 0;
    background-color: unset;
    & > .container {
      box-shadow: unset;
      // margin: 40px auto;
      // max-width: 700px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.container-card > .container {
  background-color: #fff;
  padding: 24px 60px;
  min-height: calc(100vh - 60px - 498px - 24px);
  box-shadow: 0px 0px 25px rgba(0,0,0,.05);
}
@media screen and (max-width: 768px){
  .is-plain.container-card > .container,
  .container-card > .container {
    padding: 24px;
    max-width: 100%;
  }
}
</style>
