<template>
  <div class="order-preview container" :class="`order-preview-${$i18n.locale}`">
    <h4 class="title">{{ product.sslType === 'ssl' ? product.subBrand : '' }} {{ $t('product').pricePreview.title.replace('$productName', product.productName) }}</h4>
    <section class="summary">
      <!-- <p
        v-for="(item, index) in $t('product').pricePreview.summary"
        :key="`order-preview-head-summary-item-${index}`"
      >{{ item.replace('$productName', product.productName) }}</p> -->
      <p
        v-for="(item, index) in product.priceListDescription.split('\n')"
        :key="`order-preview-head-summary-item-${index}`"
      >{{ item.replace('$productName', product.productName) }}</p>
    </section>
    <div class="preview-body flex flex-start">
      <!-- <div class="preview-left">
        <div class="price-group">
          <h4 class="price-group-title">1{{ $t('product').pricePreview.yearPrice }}</h4>
          <div class="price">{{ $t('common').currencySymbol + product.annual }}</div>
          <div class="ca-price price-item flex">
            <i class="ri-checkbox-circle-line"></i>
            {{ $t('product').pricePreview.caPrice }}: {{ $t('common').currencySymbol + product.originalPrice }}
          </div>
          <div class="ca-price price-item flex">
            <i class="ri-checkbox-circle-line"></i>
            {{ $t('product').pricePreview.left.priceSummary }}
          </div>
        </div>
        <div v-show="product.sanUcc === 'Y'" class="domain-group">
          <h4 class="domain-group-title">{{ $t('product').pricePreview.left.addSan }}</h4>
          <div class="normal-domain domain-item">{{ $t('product').pricePreview.left.oneDomainPrice.replace('$sanPrice', $t('common').currencySymbol + product.annualSan) }}</div>
          <div v-show="product.annualWildcard" class="wild-domain domain-item">
            {{ $t('product').pricePreview.left.oneWildPrice.replace('$sanWildPrice', $t('common').currencySymbol + product.annualWildcard) }}
          </div>
        </div>
        <div class="action-group flex flex-center">
          <CButton
            v-if="product.subBrand"
            :year="1"
            :brand="product.subBrand.toLowerCase()"
            :validType="product.trustLevel"
            :productType="getProductType(product.sslType)"
            type="primary" />
        </div>
      </div> -->
      <div class="preview-right">
        <section>
          <h4 class="preview-title title">{{ $t('product').pricePreview.right.title }}</h4>
          <!-- <img src="https://www.sslmarket.com/images/en/arrow_detail_en.svg" alt=""/> -->
          <!-- <img src="/images/mutil-chart.svg" alt="" :draggable="false"/> -->
          <img :src="$t('product').pricePreview.right.imgUrl" alt="" :draggable="false"/>
          <section class="preview-summary">
            {{ $t('product').pricePreview.content.title }}
          </section>
          <div
            v-for="(item, index) in $t('product').pricePreview.content.summary"
            :key="`preview-summary-item-${index}`"
            class="summary-item">
            <i class="ri-checkbox-circle-line"></i>
            {{ item }}
          </div>
        </section>
        <div class="action-group flex flex-center">
          <CButton
            v-if="product.subBrand"
            :year="1"
            :brand="product.subBrand.toLowerCase()"
            :validType="product.trustLevel"
            :productType="getProductType(product.sslType)"
            style="margin-bottom: 20px"
            type="primary"
          >
            {{ $t('product').pricePreview.btn }}
          </CButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductType } from '@/utils'
export default {
  name: 'OrderPreview',
  props: {
    product: Object
  },
  methods: {
    getProductType
  }
}
</script>

<style lang="less" scoped>
.order-preview {
  padding: 48px;
  margin-top: 48px;
  &.container {
    background-color: @bg-color;
  }
}
.order-preview-zh {
  .preview-right {
    min-height: 631px;
  }
}
.title,
.summary {
  text-align: center;
}
.title {
  color: @theme-color;
  font-size: 18px;
  margin-bottom: 24px;
}
.summary {
  line-height: 1.5em;
  font-weight: 500;
  max-width: 550px;
  margin: 0 auto;
}
.preview-body {
  margin-top: 48px;
  overflow: hidden;
}
.preview-left,
.preview-right {
  background-color: #fff;
  padding: 48px 24px 24px;
  min-height: 610px;
}
.preview-left {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.preview-right {
  flex: 1;
  margin-left: 24px;
  padding-bottom: 50px;
  margin-bottom: -50px;
  position: relative;
  img {
    display: block;
    width: 100%;
    margin-bottom: 12px;
  }
  .action-group {
    margin-top: 12px;
  }
}
.price-group,
.domain-group {
  margin-bottom: 100px;
  [class*="group-title"] {
    color: @theme-color;
    font-size: 18px;
    margin-bottom: 24px;
    text-align: center;
  }
}
.price {
  font-size: 36px;
  border-top: @border;
  border-bottom: @border;
  padding: 6px 0;
  text-align: center;
  font-weight: 500;
}
.price-item,
.domain-item,
.summary-item {
  display: flex;
  // align-items: center;
  font-weight: 500;
  margin-top: 24px;
  font-size: 16px;
  color: fade(#000, 70);
}
.summary-item:first-child {
  margin-top: 12px;
}
.ri-checkbox-circle-line {
  margin-right: 6px;
  color: @success-color;
  line-height: 1.5em;
}
.domain-item {
  font-weight: 400;
}
.preview-title {
  border-bottom: @border;
  padding-bottom: 24px;
}
.preview-summary {
  line-height: 1.5em;
}

@media screen and (max-width: 990px){
  .preview-body.flex {
    display: block;
  }
  .order-preview.container {
    max-width: 100%;
  }
  .preview-left,
  .preview-right {
    min-height: unset;
  }
  .preview-left {
    width: 100%;
    margin-bottom: 24px;
  }
  .preview-right {
    margin-left: 0;
    padding-bottom: 24px;
    margin-bottom: 0;
  }
  .price-group {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 768px){
  .order-preview.container {
    padding: 48px 0;
  }
  .summary {
    max-width: unset;
  }
}
</style>
