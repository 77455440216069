export default {
  convertCert: {
    pageTitle: 'Certificate Format Converter',
    title: 'Certificate Format Converter',
    subtitle: '',
    btn: 'Convert & Download',
    placeholder: [
      'Paste PEM Format Certificate Below',
      'Paste PEM Format Private Key Below',
      'certificate password'
    ],
    summary: [
      'Use this SSL Converter to convert SSL certificates to and from different formats such as pem, jks and pfx.',
      'All actions are done by client side, don\'t worry about the private key, no one will know it'
    ],
    form: {
      origin: 'Origin',
      target: 'Target',
      label: 'Password'
    }
  }
}
