<template>
  <div class="product-select flex flex-center">
    <el-select v-model="product" filterable placeholder="选择产品" @change="handleSelectChange">
      <el-option
        v-for="(item, index) in products"
        :key="index"
        :value="item.alias"
        :label="item.productName"
      >
        <div class="flex flex-between">
          <span>{{ item.productName }}</span>
          <el-button type="primary" size="small" @click="buy(item)">
            购买
          </el-button>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ProductSelect',
  data() {
    return {
      product: null
    }
  },
  computed: mapGetters(['products']),
  methods: {
    buy(product) {
      window.open(`https://app.certbase.com/certificate/${product.alias}`)
    },
    handleSelectChange(p) {
      const product = this.products.find(item => item.alias === p)
      this.$router.push(`/ssl/${product.subBrand.replace(/[\s]/g, '').toLowerCase()}/${p}`)
    }
  }
}
</script>

<style lang="less" scoped>
.product-select {
  padding: 20px 12px;
  background-color: #002856;
  .el-select {
    width: 100%;
    max-width: 500px;
  }
}
</style>
