<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-05-10 14:57:06
-->
<template>
  <div class="c-section" :class="{ 'is-plain': isPlain }">
    <slot name="header">
      <h4 class="title">{{ title }}</h4>
    </slot>
    <section class="c-section-content">
      <slot>{{ content }}</slot>
    </section>
  </div>
</template>

<script>

export default {
  name: 'CSection',
  props: {
    title: String,
    content: String,
    isPlain: Boolean
  }
}
</script>

<style lang="less" scoped>
.c-section {
  &.is-plain {
    .title {
      color: initial;
    }
  }
  p {
    margin-bottom: 12px;
  }
  .title {
    color: @theme-color;
    font-size: 18px;
    margin-bottom: 12px;
  }
  .c-section-content {
    line-height: 1.5em;
  }
}
</style>
