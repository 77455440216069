<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:02
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-06-02 09:54:58
-->
<template>
  <div class="layout-container">
    <Navbar />
    <router-view />
    <Footer />
    <!-- <SiteSetting /> -->
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
// import SiteSetting from '@/components/SiteSetting'

export default {
  name: 'App',
  metaInfo() {
    let title = this.$route.meta.title
    title = `${title} - Certbase`
    return {
      title
    }
  },
  components: {
    Navbar,
    Footer,
    // SiteSetting
  },
  mounted() {
    // const timer = setTimeout(() => {
    //   document.querySelectorAll('*').forEach(item => {
    //     if (!/a/i.test(item.tagName)) {
    //       // item.addEventListener("click", handler, true);
    //     }
    //     item.setAttribute('contenteditable', true)
    //   })
    //   clearTimeout(timer)
    // }, 1000)
    // document.addEventListener("click", handler, true);
    // function handler(e) {
    //   e.stopPropagation();
    //   e.preventDefault();
    // }
    // window.onbeforeunload = function() {
    //   return '确定要离开本页面吗？'
    // }
  },
}
</script>

