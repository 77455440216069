/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-05-05 09:14:20
 */
export function formatProductName (product) {
  if (!product) return {}
  const name = product.productName || ''
  const brand = product.subBrand
  const reg = RegExp(`^${brand}`)
  return reg.test(name) ? name : `${brand} ${name}`
}
