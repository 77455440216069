// 帮助中心首页中文版

export default {
  help: {
    title: 'SSL/TLS 证书帮助中心',
    subtitle: '为了使所有人更加清晰的了解我们的帮助文档，本帮助文档分为几个主题。单击您感兴趣的链接，将带您进入包含对应信息的页面。',
    quick: {
      title: 'SSL/TLS 证书帮助中心',
      options: [
        {
          label: '在服务器上安装证书',
          link: '/tools/ssl-config'
        },
        {
          label: '从HTTP强制跳转到HTTPS',
          link: '/http-to-https'
        },
        {
          label: '加快证书审核与验证速度',
          link: '/speed-up-validation'
        },
      ]
    },
    type: [
      {
        title: 'SSL/TLS 常用术语',
        icon: '',
        summary: '你是刚接触SSL/TLS证书吗？建议阅读词汇表并了解基本术语。'
      },
      {
        title: '常见问题',
        icon: '',
        summary: '这部分解答了有关使用SSL/TLS证书中一些最常见问题。'
      },
      {
        title: '联系我们',
        icon: '',
        summary: '我们的客服会为您提供专业的服务与支持。'
      }
    ],
    faq: [
      {
        title: '最受欢迎的文档',
        options: [
          {
            label: '如何获取SSL/TLS证书',
            link: '/requestnewcertificate'
          },
          {
            label: '怎么选择最适合您的数字证书',
            link: '/choosecertificate'
          },
          {
            label: '如何使用 Certbase 平台',
            link: '/explore-certbase'
          },
          {
            label: '如何验证域名控制权限和企业信息',
            link: '/vetting'
          },
          {
            label: '什么是SSL/TLS证书',
            link: '/explain-ssl-certificate'
          }
        ]
      }
    ],
     vetting: [
      {
        title: 'SSL/TLS 证书审核与认证',
        options: [
          {
            label: '域名级（DV）证书验证流程',
            link: '/domain-validation'
          },
          {
            label: '企业级（OV）证书审核与验证流程',
            link: '/organization-validation'
          },
          {
            label: '增强级（EV）证书审核与验证流程',
            link: '/extended-validation'
          },
          {
            label: '通过 DNS 解析进行域名验证（DCV）',
            link: '/dcv-dns'
          },
          {
            label: '通过 FILE 文件验证进行域名验证（DCV）',
            link: '/dcv-file'
          }
        ]
      }
    ],
     installation: [
      {
        title: 'SSL/TLS 证书安装',
        options: [
          {
            label: 'TLS 证书大多数服务器中配置生成器',
            link: '/tools/ssl-config'
          },
          {
            label: '在 IIS 中安装 SSL/TLS 证书',
            link: '/iis-installation'
          },
          {
            label: '检测证书是否安装成功',
            link: ''
          },
          {
            label: '使用证书审计功能检测网站安全设置',
            link: ''
          },
          {
            label: '中间证书、根证书与证书链',
            link: '/rootintermediatechain'
          }
        ]
      }
    ],
     commontasks: [
      {
        title: '证书工具的使用',
        options: [
          {
            label: '如何得到 .PFX（又称PKCS12）证书文件',
            link: '/get-pkcs12'
          },
          {
            label: '如何得到 .JKS 证书文件',
            link: '/get-jks'
          },
          {
            label: '关于TLS证书的各种格式概述',
            link: '/x509-format'
          }
        ]
      }
    ],
     codesigning: [
      {
        title: '代码签名证书',
        options: [
          {
            label: '驱动程序的安装与使用',
            link: '/safenet-driver-install'
          },
          {
            label: '如何选择签名工具',
            link: '/signing-tools'
          },
          {
            label: '使用SignTool进行签名',
            link: '/signing-tool-exe'
          },
          {
            label: '代码签名证书的审核与验证',
            link: '/codesigningvetting'
          },
          {
            label: '如何选择代码签名证书',
            link: '/choosecodesigning'
          }
        ]
      }
    ],
     othersigning: [
      {
        title: '邮件和文档签名',
        options: [
          {
            label: '邮件签名证书的使用教程',
            link: '/'
          },
          {
            label: '邮件签名证书的审核与验证流程',
            link: '/enterprise-smime'
          },
          {
            label: '如何使用文件签名证书',
            link: ''
          },
          {
            label: '邮件签名证书在苹果体系中的使用',
            link: ''
          },
          {
            label: '邮件签名证书提取教程',
            link: ''
          }
        ]
      }
    ],
     managetls: [
      {
        title: '证书生命周期管理',
        options: [
          {
            label: 'HTTPS漏洞检测与加密协议套件优化教程',
            link: '/help/how-create-csr'
          },
          {
            label: '证书格式转换工具的使用教程',
            link: '/x509-format'
          },
          {
            label: '证书发现工具的使用教程',
            link: ''
          },
          {
            label: '如何查询证书透明度日志',
            link: ''
          },
          {
            label: '从HTTP强制跳转到HTTPS',
            link: '/http-to-https'
          }
        ]
      }
    ],
     automation: [
      {
        title: 'SSL/TLS 证书自动化',
        options: [
          {
            label: '什么是ACME自动化',
            link: '/acme-management'
          },
          {
            label: '使用 ACME 客户端签发与安装证书',
            link: ''
          },
          {
            label: 'Certbase API 自动化方案',
            link: ''
          },
          {
            label: '简单使用 Postman 申请证书',
            link: ''
          }
        ]
      }
    ],
    navbar: {
      left: {
        contents: {
          title: '帮助内容',
          options: [
            { label: '审核与验证流程', link: '/1' },
            { label: 'TLS 证书安装流程', link: '/2' },
            { label: '常见问题', link: '/help/faq' },
            { label: 'Certbase 平台使用教程', link: '/4' }
          ]
        },
        other: {
          title: '关键一招',
          options: [
            { title: 'TLS 常用术语', label: '专业名词解释', link: '/help/glossary', icon: '/images/robot.svg' },
            { title: '常见问题', label: '通用答案与故障排除', link: '/help/faq', icon: '/images/robot.svg' },
            { title: '证书自动化', label: 'ACME 与 API自动化', link: '/3-', icon: '/images/robot.svg' }
          ]
        }
      },
      right: {
        title: '帮助中心',
        summary: '在这里您可以找到有关证书的常见问题解答。',
        btn: [
          {
            link: '/help',
            linkText: '查看帮助文档'
          },
          {
            link: '',
            linkText: '在线技术支持'
          }
        ]
      }
    }
  }
}
