/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-29 10:08:22
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-06-02 10:06:52
 */
import { page } from '../utils'

const certhubRoute = {
  path: '/certhub',
  component: page('new/certhub/index'),
  redirect: '/certhub/discovery',
  children: [
    {
      path: 'discovery',
      component: page('new/certhub/discovery'),
      meta: {
        title: '发现证书'
      }
    },
    {
      path: 'inventory',
      component: page('new/certhub/inventory'),
      meta: {
        title: '证书库存管理'
      }
    },
    {
      path: 'analyse',
      component: page('new/certhub/analyse'),
      meta: {
        title: '证书分析工具'
      }
    },
    {
      path: 'audit',
      component: page('new/certhub/audit'),
      meta: {
        title: 'SSL/TLS 证书审计与评估报告'
      }
    },
    {
      path: 'monitor',
      component: page('new/certhub/monitor'),
      meta: {
        title: '证书监控'
      }
    },
    {
      path: 'log',
      component: page('new/certhub/log'),
      meta: {
        title: '证书透明度日志'
      }
    },
  ]
}

export default certhubRoute