/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-05-25 09:31:31
 */
// import api from './index'
import VueCookie from 'vue-cookie'

const local = VueCookie.get('locale') || 'zh'
 
export function getProducts (language = local) {
  // eslint-disable-next-line
  const products = require(`@/static/language/product_${language}.json`)
  // eslint-disable-next-line
  return (Promise.resolve(products))
}

export function getBrands (language = local) {
  // eslint-disable-next-line
  const brands = require(`@/static/language/brand_${language}.json`)
  // eslint-disable-next-line
  return (Promise.resolve(brands))
}

export function getTypes (language = local) {
  // eslint-disable-next-line
  const types = require(`@/static/language/type_${language}.json`)
  // eslint-disable-next-line
  return (Promise.resolve(types))
}

export default {
  getProducts
}
