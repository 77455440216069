// 证书品牌
const brand = {
  title: 'BY The CA'
}

// 证书类型
const type = {
  title: 'By Validation',
  options: [
    {
      label: 'Domain Validation (DV)',
      link: '/ssl/domain-validation'
    },
    {
      label: 'Organization Validation (OV)',
      link: '/ssl/organization-validation'
    },
    {
      label: 'Extended Validation (EV)',
      link: '/ssl/extended-validation'
    },
    {
      label: 'Basic TLS Certificates',
      link: '/ssl/single-domain'
    },
    {
      label: 'Wildcard TLS Certificates',
      link: '/ssl/wildcard'
    },
    {
      label: 'Flex TLS Certificates',
      link: '/ssl/flex-tls'
    },
    {
      label: 'Public IP Address TLS',
      link: '/ssl/public-ip-address'
    },
    {
      label: 'China OCSP TLS',
      link: '/ssl/china-optimized-ocsp-tls'
    }
  ]
}

// 数字签名
const sign = {
  title: 'Digital Signing',
  options: [
    {
      label: 'Starndard Code Signing',
      link: '/digital-signing/code-signing'
    },
    {
      label: 'EV Code Signing',
      link: '/digital-signing/ev-code-signing'
    },
    {
      label: 'Email Signing',
      link: '/digital-signing/email-signing'
    },
    {
      label: 'Verified Mark Certificates',
      link: '/digital-signing/verified-mark-certificates'
    },
    {
      label: 'Document Signing',
      link: '/digital-signing/document-signing'
    },
    {
      label: 'AATL PDF Signing',
      link: '/digital-signing/aatl-signing-certificates'
    }
  ]
}

// 证书管理
const mnage = {
  title: 'Certhub',
  options: [
    {
      label: 'Certificate Tools',
      link: '/tools/parse-cert'
    },
    {
      label: 'Certificate Discovery',
      link: '/certhub/discovery'
    },
    {
      label: 'Certificate Inventory Tool',
      link: '/certhub/inventory'
    },
    {
      label: 'Certificate Analysis Tool',
      link: '/certhub/analyse'
    },
    {
      label: 'Certificate Audit',
      link: '/certhub/audit'
    },
    {
      label: 'Certificate Monitor',
      link: '/certhub/monitor'
    },
    {
      label: 'Certificate Transparency Search',
      link: '/certhub/log'
    }
  ]
}

// 关于我们
const about = {
  title: 'Company',
  options: [
    {
      label: 'About Us',
      link: '/about-us'
    },
    {
      label: 'Contact Us',
      link: '/contact-us'
    },
    {
      label: 'Trust Center',
      link: '/trust-center'
    },
    {
      label: 'Accept Payments',
      link: '/bank-details-and-payment-methods'
    },
    {
      label: 'Enterprise',
      link: '/partner/enterprise'
    },
    {
      label: 'Reseller',
      link: '/partner/reseller'
    },
    {
      label: 'Affiliates',
      link: '/partner/affiliate'
    },
    {
      label: 'Members',
      link: '/partner/member'
    },
  ]
}

export default {
  vfooter: {
    type,
    sign,
    mnage,
    about,
    brand
  }
}