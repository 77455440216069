export default {
  "btn": {
    "bind": "Bind",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "save": "Save",
    "update": "Update",
    "reUpdate": "修改",
    "next": "Next step",
    "off": "Close",
    "on": "Open",
    "create": "Create",
    "decode": "Decode"
  },
  "common": {
    "day": "Day",
    "brand": "Brand",
    "year": "Year",
    "country": "Country",
    "price": "Our price",
    "originalPrice": "CA price",
    "minute": "Minute",
    "currency": "Dollar",
    "currencySymbol": "$",
    "commonName": "Common Name",
    "privateKey": "Private Key",
    "productName": "Product name",
    "trust": "Trust level",
    "issueTime": "Issue time",
    "validity": "Validity",
    "secures": "Secures",
    "publicKeyAlgorithm": "Public key algorithm",
    "symmetricEncryption": "Symmetric encryption",
    "publicKeyLength": "Public key length",
    "rootCa": "Root CA",
    "refundSince": "Money-back guarantee",
    "reIssue": "Certificate reissue",
    "supportMulti": "Multi-domain support (SAN)",
    "warranty": "Warranty",
    "buy": "Buy",
    "buyNow": "Buy Now",
    "login": "Sign in",
    "register": "Get started",
    "compatible": "Browser Recognition",
    "submit": "Submit",
    "danger": "Not Secure",
    "discount": "Discount",
    "privatekeyontoken": "Private key on token",
    "addDomains": "Additional domains",
    "dv": "domain vetting",
    "ov": "organization vetting",
    "ev": "extended validation",
    "provice": {
      "label": "Provice",
      "place": "provice"
    },
    "city": {
      "label": "City",
      "place": "city"
    },
  },
  "productType": {
    "email-signing": "Secure Email Certificates",
    "code-signing": "Code Signing Certificates",
    "ev-code-signing": "EV Code Signing Certificates",
    "document-signing": "Document Signing Certificates",
    "single-domain": "Single Domain SSL",
    "multi-domain": "Multi-Domain (SAN)",
    "wildcard": "Wildcard Certificates",
    "domain-validation": "Domain Validation",
    "organization-validation": "Business Validation",
    "extended-validation": "Extended Validation",
    "other": "Other Digital Certificates"
  }
}
