/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:31:09
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-06-07 20:34:18
 */
import toolsRoute from './tools'
import certhubRoute from './certhub'
import solutionsRoutes from './solutions'
import partnerRoute from './partner'
import protocolRoutes from './protocol'
import { page } from '../utils'

export default [
  {
    path: '/',
    component: page('new/index'),
    meta: {
      title: '中立TLS//PKI 提供商 - 加密、身份验证、自动化'
    }
  },
  {
    path: '/ssl-certificates-price',
    component: page('new/ssl-certificates-price/index'),
    meta: {
      title: '产品定价'
    }
  },
  {
    path: '/domain-validation-certificates',
    component: page('new/ssl/domain-validation-certificates'),
    meta: {
      title: 'DV SSL Certificate'
    }
  },
  {
    path: '/organization-validation-certificates',
    component: page('new/ssl/organization-validation-certificates'),
    meta: {
      title: 'OV SSL Certificate'
    }
  },
  {
    path: '/ev-ssl-certificates',
    component: page('new/ssl/ev-ssl-certificates'),
    meta: {
      title: 'EV SSL Certificate'
    }
  },
  {
    path: '/wildcard-ssl-certificates',
    component: page('new/ssl/wildcard-ssl-certificates'),
    meta: {
      title: 'Wildcard SSL Certificate'
    }
  },
  {
    path: '/multi-domain-ssl-certificates',
    component: page('new/ssl/multi-domain-ssl-certificates'),
    meta: {
      title: 'Multi-domain SSL Certificate'
    }
  },
  {
    path: '/single-domain-ssl-certificates',
    component: page('new/ssl/single-domain-ssl-certificates'),
    meta: {
      title: 'Multi-domain SSL Certificate'
    }
  },
  {
    path: '/code-signing-certificates',
    component: page('new/ssl/code-signing-certificates'),
    meta: {
      title: 'Code Signing SSL Certificate'
    }
  },
  {
    path: '/search',
    component: page('search/index'),
    meta: {
      title: '产品搜索'
    }
  },
  {
    path: '/saas',
    component: page('new/saas'),
    meta: {
      title: 'SAAS 服务'
    }
  },
  {
    path: '/website-traffic',
    component: page('new/website-traffic'),
    meta: {
      title: '网站流量'
    }
  },
  {
    path: '/software-code',
    component: page('new/software-code'),
    meta: {
      title: '软件代码'
    }
  },
  {
    path: '/data-security',
    component: page('new/data-security'),
    meta: {
      title: '数据安全'
    }
  },
  {
    path: '/identification',
    component: page('new/identification'),
    meta: {
      title: '身份信息'
    }
  },
  {
    path: '/brand/:brand',
    component: page('brand/_brand/index')
  },
  // {
  //   path: '/digital-signing/:sslType/:alias',
  //   component: page('new/digital-signing/_sslType/_alias')
  // },
  // {
  //   path: '/digital-signing/:sslType',
  //   component: page('new/digital-signing/_sslType/index')
  // },
  {
    path: '/digital-signing',
    component: page('new/digital-signing/index'),
    children: [
      {
        path: '/digital-signing/:sslType',
        component: page('new/digital-signing/_sslType/index')
      },
      {
        path: '/digital-signing/:sslType/:alias',
        component: page('new/digital-signing/_sslType/_alias')
      }
    ]
  },
  {
    path: '/ssl',
    redirect: '/ssl/domain-validation',
    component: page('new/ssl/index'),
    children: [
      {
        path: '/ssl/:sslType',
        component: page('new/ssl/_sslType/index')
      },
      {
        path: '/ssl/:sslType/:alias',
        component: page('new/ssl/_sslType/_alias')
      }
    ]
  },
  {
    path: '/about-us',
    component: page('new/about-us'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/references',
    component: page('new/references'),
    meta: {
      title: '我们的客户'
    }
  },
  {
    path: '/contact-us',
    component: page('new/contact-us'),
    meta: {
      title: '联系我们'
    }
  },
  {
    path: '/bank-details-and-payment-methods',
    component: page('new/bank-details-and-payment-methods'),
    meta: {
      title: '银行信息以及付款方式'
    }
  },
  toolsRoute,
  certhubRoute,
  partnerRoute,
  ...solutionsRoutes,
  ...protocolRoutes,
  {
    path: '*',
    component: page('_')
  },
]