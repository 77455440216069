import application from './application_zh'
import common from './common_zh'
import crypto from './crypto_zh'
import zh from './zh'
import help from './help_zh'
import report from './report_zh'
import keyTextarea from './key_textarea_expend_zh'
import nofund from './nofund_zh'
import seo from './seo_zh'
import x509 from './x509_zh'
import certhub from './certhub/options_zh'
import partner from './partner/index_zh'
import compliance from './compliance.zh'
import complianceAuth from './compliance/evaluation.zh'
import nnavbar from './navbar_zh'
import vfooter from './footer_zh'
import sslType from './type_zh'
import nbrand from './brand_zh'
// compliance 群组
import publicCloud from './compliance/cloud.zh'
import zerotrust from './compliance/zerotrust.zh'
import pki from './compliance/pki.zh'
import certificationalliance from './compliance/certificationalliance.zh'
import gateway from './compliance/gateway.zh'
import idc from './compliance/idc.zh'
import sase from './compliance/sase.zh'
import scalesmime from './compliance/scalesmime.zh'
import signature from './compliance/signature.zh'

export default {
  ...application,
  ...zh,
  ...common,
  ...crypto,
  ...help,
  ...report,
  ...keyTextarea,
  ...nofund,
  ...seo,
  ...x509,
  ...certhub,
  ...partner,
  ...compliance,
  ...complianceAuth,
  ...nnavbar,
  ...vfooter,
  ...sslType,
  ...nbrand,
  ...publicCloud,
  ...zerotrust,
  ...pki,
  ...certificationalliance,
  ...idc,
  ...sase,
  ...gateway,
  ...scalesmime,
  ...signature
}
