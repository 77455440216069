export default {
  keyTextarea: {
    textarea: [
      '您可以点击文本框粘贴',
      '也',
      '可以将文件拖到此处，或',
      '点击此处上传',
      ''
    ],
    place: '在此处粘贴 PEM 格式的 CSR'
  }
}
