export default {
  title: '了解更多证书管理',
  summury: '全面了解我们SSL/TLS管理平台中的所有功能',
  options: {
    discovery: {
      summary: '使用发现证书功能寻找已知和未知的 SSL/TLS证书',
      link: '/certhub/discovery',
      linkText: '探索发现证书'
    },
    inventory: {
      summary: '使用证书库存管理工具集中管理所有的 SSL/TLS 证书',
      link: '/certhub/inventory',
      linkText: '探索证书库存管理'
    },
    analyse: {
      summary: '使用证书分析工具评估和追踪网络健康，精准定位每一张证书已知或者未知的问题',
      link: '/certhub/analyse',
      linkText: '探索证书分析'
    },
    audit: {
      summary: '依靠网站证书审计寻找漏洞进行监控和统计，协助您构建合规的 HTTPS 站点',
      link: '/certhub/audit',
      linkText: '探索证书审计'
    },
    monitor: {
      summary: '开箱即用SSL/TLS证书监控，定期检查Web服务器中安装的SSL/TLS证书的有效性',
      link: '/certhub/monitor',
      linkText: '探索证书监控'
    },
    log: {
      summary: '通过证书日志、监控和审计系统，证书透明度使网站用户和域名持有者可以识别不当或恶意签发的证书，以及识别数字证书认证机构（CA）的作为',
      link: '/certhub/log',
      linkText: '探索证书透明度日志'
    }
  }
}
