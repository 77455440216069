/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-05-05 17:50:25
 */
export * from './cookie'
export * from './scroll'

// Get product validite type
export function getValidType (str) {
  if (/dv/i.test(str)) return 'dv'
  if (/ov/i.test(str)) return 'ov'
  if (/ev/i.test(str)) return 'ev'
  return ''
}

// Get product type
export function getProductType (str) {
  if (/ssl/i.test(str)) return 'ssl'
  if (/doc/i.test(str)) return 'doc'
  if (/code/i.test(str)) return 'code'
  if (/smime|email/i.test(str)) return 'email'
  return ''
}

// Gen hash string
export function hashHex() {
  return (Math.random() * 10000).toString(16).replace('.', '')
}

// 截流函数
export const delay = (function() {
  let timer = 0
  return function(callback, ms) {
    clearTimeout(timer)
    timer = setTimeout(callback, ms)
  }
})()