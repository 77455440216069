<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-04-28 14:19:02
-->
<template>
  <header class="app-navbar" :class="`navbar--${$store.state.navbarClassStyle}`">
    <Topbar />
    <NavMenu />
    <ProductSelect />
  </header>
</template>

<script>
import Topbar from './Topbar.vue';
import NavMenu from './NavMenu.vue';
import ProductSelect from './ProductSelect.vue';
export default {
  name: 'Navbar',
  components: {
    Topbar,
    NavMenu,
    ProductSelect
  }
}
</script>
