import home from './home_en'
import chain from './chain_en'
import discovery from './discover_en'
import inventory from './inventory_en'
import analyse from './analyse_en'
import audit from './audit_en'
import monitor from './monitor_en'
import log from './log_en'
// tools
import sslConfig from './tls_config_en'
import CreateCsr from './create_csr_en'
import ParseCsr from './parse_csr_en'
import ParseCert from './parse_cert_en'
import ConvertCert from './convert_cert_en'
import MatchCert from './match_cert_en'
export default {
  certhub: {
    navLeftTitle: 'Tools',
    navLeft: [
      [
        { label: 'CSR Generator', path: '/tools/gen-csr' },
        { label: 'CSR Decoder', path: '/tools/parse-csr' },
        { label: 'Certificate Decoder', path: '/tools/parse-cert' },
        { label: 'Certificate Key Matcher', path: '/tools/match' },
        { label: 'Certificate Format Converter', path: '/tools/convert-cert' },
      ],
      [
        { label: 'TLS Configuration', path: '/tools/ssl-config' }
      ]
    ],
    navRight: [
      { label: 'TLS Certificate Management', path: '/certhub' },
      { label: 'Certificate Discovery', path: '/certhub/discovery' },
      { label: 'Certificate Inventory Tool', path: '/certhub/inventory' },
      { label: 'Certificate Analysis Tool', path: '/certhub/analyse' },
      { label: 'Certificate Audit', path: '/certhub/audit' },
      { label: 'Certificate Monitor', path: '/certhub/monitor' },
      { label: 'Certificate Transparency Search', path: '/certhub/log' }
    ],
     // 折叠导航右侧内容
    desc: {
      title: 'Certhub',
      linkText: 'Try for Free',
      link: '', // 前往地址
      summary: ''
    },
    log,
    home,
    chain,
    audit,
    analyse,
    monitor,
    discovery,
    inventory
  },
  ...sslConfig,
  ...CreateCsr,
  ...ParseCsr,
  ...ParseCert,
  ...ConvertCert,
  ...MatchCert
}
