export default {
  "matchCert": {
    "pageTitle": "数字证书公私钥匹配",
    "title": "数字证书公私钥匹配",
    "subtitle": "",
    "btn": "立即匹配",
    "placeholder": [
      "在此处粘贴您 PEM 的格式证书",
      "在此处粘贴您 PEM 的格式 CSR",
      "在此处粘贴您 PEM 的格式私钥"
    ],
    "radios": [
      '证书匹配私钥',
      'CSR 匹配私钥',
      '证书匹配 CSR'
    ],
    "summary": [
      "公私钥匹配，填写获得到的公钥私钥，验证它们是否匹配。",
      "Certbase.com 提供的证书工具供所有用户免费使用，Certbase 不记录任何信息，用户在使用证书工具中、使用证书工具后产生的任何错误或造成任何损失，Certbase 不承担任何责任。"
    ]
  }
}
