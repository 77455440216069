<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-04-26 14:20:46
-->
<template>
  <div class="legal-layout">
    <div class="leage-head">
      <h1 class="legal-title">
        <span>{{ title }}</span>
      </h1>
      <h4 class="legal-subtitle">{{ subtitle }}</h4>
      <slot name="header-expand"></slot>
    </div>
    <div class="leagl-body">
      <div class="container">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarkContainer',
  props: {
    title: String,
    subtitle: String
  }
}
</script>

<style lang="less" scoped>
.leage-head {
  padding: 48px 0;
  text-align: center;
}
.legal-title {
  span {
    color: #fff;
    padding: 6px 12px;
    display: inline-block;
    background-color: @theme-color;
  }
}
.legal-subtitle {
  font-weight: 500;
  font-size: 16px;
  margin: 24px auto;
  padding: 0 24px;
  max-width: 500px;
}
.leagl-body {
  padding: 48px 0;
  background-color: #fff;
}
</style>
