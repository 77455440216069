<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-04-26 10:01:46
-->
<template>
  <a class="signatures" href="javascript:void(0)">
    <template v-if="product.sslType === 'ssl'">
      <img
        v-if="signature && signature.coverImgUrl"
        :src="signature.coverImgUrl"
        :alt="product.subBrand"
        :draggable="false" />
      <i v-else class="ri-close-fill"></i>
    </template>
    <template v-if="content()">
      <p v-if="content() && content().value" class="product-summary-card-content">{{ content().value }}</p>
      <i v-else class="ri-close-fill"></i>
      <span>{{ $t('product').details[content().label] }}</span>
    </template>
  </a>
</template>

<script>
import { computed, SetupContext } from '@vue/composition-api'

function formatSubBrand (brand) {
  return brand.toLowerCase().replace(/[\s]/g, '')
}

export default {
  name: 'Signatures',
  props: {
    product: Object
  },
  setup (props, ctx) { // eslint-disable-line
    const root = ctx.root
    const signature = computed(() => {
      if (!props.product.subBrand) return
      let brand = props.product.subBrand
      brand = brand.toLowerCase()
      const signatures = root.$t('footer').signature // eslint-disable-line
      return (signatures).find(item => item.brand === formatSubBrand(brand))
    })
    // code -> Company name
    // email -> secure addresses
    // document -> signatures annually
    const content = () => {
      const type = props.product.sslType

      const value = props.product.siteSealOrCompanyName
      if (/code/.test(type)) {
        return {
          label: 'codeLabel',
          value
        }
      }
      if (/email/.test(type)) {
        return {
          label: 'emailLabel',
          value
        }
      }
      if (/document/.test(type)) {
        return {
          label: 'documentLabel',
          value: value.replace(/[^0-9]/ig, '')
        }
      }
    }

    return {
      signature,
      content
    }
  }
}
</script>

<style lang="less" scoped>
.signatures img {
  display: block;
  max-width: 110px;
  margin: 0 auto;
}
.ri-close-fill {
  display: block;
  font-size: 36px;
  margin-bottom: 12px;
  color: @text-color-l;
}
.product-summary-card-content {
  font-size: 26px;
  margin-bottom: 12px;
}
</style>
