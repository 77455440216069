/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-05-05 09:50:10
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-06-02 10:04:33
 */
import { page } from '../utils'

export default [
  {
    path: '/compliance',
    component: page('new/compliance'),
    meta: {
      title: '等保整改'
    }
  },
  {
    path: '/evaluation',
    component: page('new/evaluation'),
    meta: {
      title: '等保评测'
    }
  },
  {
    path: '/public-cloud',
    component: page('new/public-cloud'),
    meta: {
      title: '公有云'
    }
  },
  // {
  //   path: '/zerotrust',
  //   component: page('new/zerotrust'),
  //   meta: {
  //     title: '飞连'
  //   }
  // },
  {
    path: '/private-pki',
    component: page('new/private-pki'),
    meta: {
      title: '私有 PKI'
    }
  },
  {
    path: '/signature',
    component: page('new/signature'),
    meta: {
      title: '可信电子签名'
    }
  },
  {
    path: '/certificationalliance',
    component: page('new/certificationalliance'),
    meta: {
      title: '可信认证联盟'
    }
  },
  {
    path: '/scalesmime',
    component: page('new/scalesmime'),
    meta: {
      title: '客户端证书'
    }
  },
  {
    path: '/sase',
    component: page('new/sase'),
    meta: {
      title: '云安全访问服务'
    }
  },
  {
    path: '/gateway',
    component: page('new/gateway'),
    meta: {
      title: 'SSL应用安全网关'
    }
  },
  {
    path: '/idc',
    component: page('new/idc'),
    meta: {
      title: 'IDC 服务'
    }
  },
]