// Get cookie for cookie string
export function getCookie(cookies, key) {
  if (!cookies || !key) return
  let start = cookies.indexOf(key + '=')
  if (start === -1) return
  start = start + key.length + 1
  let position = cookies.substring(start)
  let end = position.indexOf(';')
  position = position.substring(0, end === -1 ? undefined : end)
  return position.trim()
}
