const reasonsToBuy = [
  {
    title: `快速、简单、方便`,
    content: '我们经过优化的系统和流程可使您可以快速轻松地购买、安装、使用、管理和更新您的证书——无论您需要多少。'
  },
  {
    title: `我们随时为您提供帮助`,
    content: '您的安全是我们的首要任务，这就是我们提供一流支持的原因。如果您需要有关我们某款产品的帮助，请随时与训练有素的安全专家交谈。'
  },
  {
    title: `我们只实践最佳实践`,
    content: '诚信是信任的核心，是我们品牌的基础。我们的内部数据系统不仅符合 PCI 安全标准，而且我们的业务实践还通过了 WebTrust 认证：安全性、可用性、处理完整性、机密性和隐私。'
  },
  {
    title: `以超值的价格获得最佳的安全`,
    content: '我们以尽可能低的成本向全球客户提供全系列的 256 位加密 TLS/SSL 产品，包括 EV、OV、DV、通配符和多域 SAN SSL 证书。更好的是，我们的解决方案几乎支持所有浏览器和流行的移动设备。'
  },
  {
    title: '声誉很重要',
    content: `Certbase 是一个成熟、备受推崇的安全品牌，由 DigiCert、Sectigo、GlobalSign 和 BitCert 等世界顶级 CA 提供支持，这些 CA 是业内最值得信赖的证书颁发机构。`
  }
]

// try-it-30d
const tryIt30d = [
  {
    title: '试用 30 天！',
    content: '购买，安装并试用。 在签发后 30 天内因任何原因取消可获得 100% 的全额退款。'
  },
  {
    title: '签发速度快',
    content: '享受业界超快的证书颁发时间。'
  },
  {
    title: '税务局 A 级纳税等级',
    content: `我们正在庆祝我们的第 6 个年头！ 查看我们的税局 A 评级和认证`
  },
]

// About
const about = `我们为世界各地的公司和组织提供了 6 年的优质服务。 我们的核心原则是经验、知识和客户满意度。`

export default {
  nbrand: {
    brandCardTitle: `为您的网站添加一个 $brand 信任签章`,
    reasonsToBuy,
    tryIt30d,
    about
  }
}