<template>
  <!-- 主页卡片 -->
  <div
    class="card"
    :class="{
      'no-summary' : !summary,
      'is-black': !isUndefiend(isBlack),
      'is-product': !isUndefiend(isProduct)
    }">
    <slot v-if="!isUndefiend(isProduct)">
      <h4 class="subtitle">{{ subtitle }}</h4>
      <h2 class="title">
        <a v-show="titleLink" :href="titleLink">{{ title }}</a>
        <span v-show="!titleLink">{{ title }}</span>
      </h2>
      <section v-if="summary" class="summary">{{ summary }}</section>
      <div class="card-bottom flex">
        <!-- <div class="price">
          <span>{{ price }}</span>
        </div> -->
          <!-- valid-type="" -->
        <CButton
          border
          type="primary"
          :valid-type="getValidType(title)"
        />
      </div>
    </slot>
    <slot v-else>
      <img
        v-if="isUndefiend(noIcon)"
        :src="icon"
        alt=""
        :draggable="false"
        :class="{ block: !icon }">
      <h4 class="title" :class="{ 'title-onicon': !isUndefiend(noIcon) }">{{ title }}</h4>
      <section v-if="summary" class="summary">{{ summary }}</section>
    </slot>
  </div>
</template>

<script>
import { getValidType } from '@/utils'
const isUndefiend = (val) => val === undefined

export default {
  name: 'Card',
  props: {
    noIcon: String,
    icon: String,
    price: [Number, String],
    title: String,
    summary: String,
    subtitle: String,
    isBlack: String, // is gary background? white is default background color
    isProduct: String, // is product mode ?
    titleLink: String
  },
  setup () {
    return {
      isUndefiend,
      getValidType
    }
  }
}
</script>
<style lang="less" scoped>
.card {
  padding: 24px;
  max-width: calc(400px - 16px);
  background-color: #fff;
  border-radius: 8px;
  border: unset;
  &.no-summary {
    h4 {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  &.is-black {
    background-color: @bg-color;
  }
  &.is-product {
    text-align: left;
    .subtitle {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
    }
    .title {
      color: @theme-color;
      border-bottom: 1px solid #ccc;
    }
    .summary {
      margin-bottom: 48px;
      padding-top: 12px;
    }
    .price {
      color: @theme-color;
      span:first-child {
        font-size: 1.5em;
        font-weight: 500;
      }
      span:nth-child(2) {
        margin: 0 6px;
      }
    }
  }
  &.is-border {
    border: 1px solid #dadce0;
    background-color: #fff;
    border-radius: 8px;
    transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);
    color: #202124;
    &:hover {
      box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%);
    }
  }
  .title {
    padding: 12px 0;
    font-weight: 400;
    &.title-onicon {
      padding-top: 0;
    }
  }
  .summary {
    line-height: 24px;
  }
  h4 {
    font-size: 22px;
    text-align: center;
  }
  img {
    display: block;
    width: 36px;
    height: 36px;
    margin: 0 auto;
  }
  // when icon is undefiend
  img.block {
    background-color: gray;
  }
}
</style>
