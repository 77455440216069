<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-05-10 14:54:04
-->
<template>
  <div
    class="banner-layout"
    :class="{ 'is-plain': isPlain }"
    :style="`--banner-bgurl:url('${bgUrl}')`"
  >
    <!-- :style="`background-image: url(${bgUrl})`" -->
    <div class="container">
      <div class="banner-layout-body">
        <h1 v-html="title" class="banner-layout-title back-selection"></h1>
        <p class="back-selection">{{ summary}}</p>
        <slot v-if="isBtn" name="btn">
          <CButton :link="link">{{ linkText }}</CButton>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Banner',
  props: {
    link: String,
    isPlain: Boolean,
    isBtn: {
      type: Boolean,
      default: true
    },
    linkText: {
      type: String,
      default: '立即体验'
    },
    title: {
      type: String,
      default: 'Title'
    },
    summary: {
      type: String,
      default: ''
    },
    bgUrl: {
      type: String,
      default: '/images/certbase-banner.svg'
    }
  }
}
</script>

<style lang="less" scoped>
.banner-layout {
  height: 500px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: @theme-color;
  &.is-plain {
    color: initial;
    background-color: unset;
    .container {
      border-bottom: @border;
    }
    .banner-layout-title {
      font-size: 64px;
      line-height: 76px;
      font-weight: 400;
    }
  }
  .container {
    padding: 48px 0 48px 96px;
    background-image: var(--banner-bgurl);
    background-repeat: no-repeat;
    background-position: right;
    background-size: auto 100%;
  }
  .btn {
    margin-top: 12px;
  }
  p {
    line-height: 1.5em;
  }
}
.banner-layout-body {
  max-width: 450px;
  min-height: 233px;
}
.back-selection {
  margin: 24px 0;
}

@media screen and (max-width: 768px){
  .banner-layout {
    height: auto;
    .container {
      padding: 48px 0;
      background: none;
    }
  }
}
</style>
