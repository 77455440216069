<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-05-31 13:43:15
-->
<template>
  <div class="code-signing-table container">
    <CSection v-if="product" :title="$t('codeSigning').title">
      <p v-for="(item, index) in $t('codeSigning').summary" :key="`code-signing-table-summary-${index}`">
        {{ item.replace('$productName', product.productName) }}
      </p>
    </CSection>
    <ul class="table">
      <li
        v-for="(item, index) in $t('codeSigning').table"
        :key="`code-signing-cell-${index}`"
        class="cell flex"
      >
        <div class="cell-icon" :style="`background-position: 0 ${item.offset}px`"></div>
        <div class="cell-body">
          <h4 class="cell-title">{{ item.title }}</h4>
          <section class="cell-content">{{ item.content }}</section>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CodeSigningTable',
  props: {
    product: Object
  }
}
</script>

<style lang="less" scoped>
.table {
  // border: @border;
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(3, 33.33%);
  word-break: break-all;
  width: 100%;
  .cell {
    padding: 48px 24px;
    border: 1px solid #dadce0;
    background-color: #fff;
    border-radius: 8px;
    transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);
    color: #202124;
    font-weight: normal;
    overflow: hidden;
    grid-row-end: span 32;
    margin-bottom: 20px;
    display: flex;
    &:hover {
      box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%);
    }
    img {
      width: 80px;
      padding-left: 16px;
    }
  }
  .cell:not(:first-child) {
    border-top: @border;
  }
  .cell-icon {
    width: 40px;
    min-height: 50px;
    margin-right: 24px;
    background: transparent url('../../assets/images/code-signing-target.png') no-repeat scroll;
    background-position: 0 -444px;
  }
  .cell-body {
    width: calc(100% - 64px);
  }
  .cell-title {
    color: @theme-color;
    font-size: 18px;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 768px) {
  .code-signing-table {
    max-width: 100%;
  }
  .table {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
