Date.prototype.format = function (fmt) {   // eslint-disable-line
  let o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'S': this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}

// 格式化时间
export let formatDate = (date = new Date(), format_str = 'yyyy-MM-dd') => {
  if (!date) {
    return '-'
  } else {
    if (/1-01-01T00:00:00Z/.test(date)) return '-'
    const currentDate = new Date(date).format(format_str)
    if (/NaN-aN-aN/.test(currentDate)) return '-'
    return currentDate
  }
}

// 将数字格式化为小数保留两位小数
export let formatDecimal = (value) => {
  if (!value || value === 0) return '0.00'
  let rs = '0.00'
  if (typeof value === 'string') {
    rs = parseFloat(value).toFixed(2)
  } else {
    rs = value.toFixed(2)
  }
  return rs
}

// 格式化名称
export let formatName = ({ firstName, lastName }) => {
  if (!firstName || !lastName) return lastName + firstName
  let fullName = lastName.trim() + firstName.trim()
  if (!/^[\u4E00-\u9FA5]+(·[\u4E00-\u9FA5]+)*$/.test(fullName)) {
    fullName = firstName.trim() + ' ' + lastName.trim()
  }
  return fullName
}
// 格式化支付方式状态
export function formatPayStatus(code) {
  let status = {
    1: '未支付',
    2: '已支付',
    3: '退款中',
    4: '已退款',
    5: '已取消',
    6: '已签发'
  }
  return status[code]
}
// 格式化支付方式
export function formatPayMode(code) {
  let modes = {
    0: '支付宝',
    1: '微信支付',
    2: 'PayPal',
    3: '虚拟货币',
    4: '银行转帐'
  }
  return modes[code]
}
// 订单状态
export function formatOrderStatus(code) {
  code = typeof code === 'string' ? Number(code) : code
  return {
    1001: '等待支付',
    1002: '等待验证',
    1003: '重新提交',
    1004: '已经取消',
    1006: '重新提交' // CA订单请求超时
  }[code]
}
// 格式化通知方式
export function formatNotifyWay(code) {
  return {
    tel: '电话',
    email: '邮箱',
    wechat: '微信'
  }[code]
}
// 格式化折扣
export function formatDiscount (discount) {
  return discount >= 1 ? '无折扣' : `${discount * 10}折`
}

// 格式化签名算法
export function formatSignature (signature) {
  return signature.substr(0, 6)
}

// 格式化验证方法
export function formatDomainVerify (verify) {
  return {
    'CNAME_CSR_HASH': 'DNS 验证',
    'HTTP_CSR_HASH': '文件验证',
    'EMAIL': '邮件验证'
  }[verify]
}
// 格式化下单页面验证方法
export function formatLocalDomainVerify (verify) {
  return {
    'dns': 'DNS 验证',
    'file': '文件验证',
    'email': '邮件验证'
  }[verify]
}

// 格式化证书类型（综合）
export function formatCertType(type) {
  let result = { validType: '', numType: '' }
  if (!type) return result
  if (type === 'client') {
    result.validType = 'client'
  }
  const types = type.split('_')
  result.validType = types[0]
  result.numType = types[1]
  return result
}
// 获取证书验证类型
export function formatCetyValidType(type, toUpper) {
  let result = { validType: '', numType: '' }
  if (!type) return result
  if (type === 'client') {
    result.validType = 'client'
  }
  const types = type.split('_')

  return toUpper ? types[0].toUpperCase() : types[0]
}
// 获取证书验证域名数量类型
export function formatCetyNumType(type) {
  let result = { validType: '', numType: '' }
  if (!type) return result
  if (type === 'client') {
    result.validType = 'client'
  }
  const types = type.split('_')
  return types[1]
}
// 格式化价格 数字年限对应价格
export function formatPriceByYear(year, certData) {
  let result = [] // [普通域名价格, 通配域名价格]
  const {
    sanAnnual, sanWildcardAnnual,
    sanBiennial, sanWildcardBiennial,
    Triennial, sanWildcardTriennial,
    Quadrennial, sanWildcardQuadrennial,
    Quinquennial, sanWildcardQuinquennial
  } = certData
  switch (year) {
    case 1:
      result = [sanAnnual, sanWildcardAnnual]
      break
    case 2:
      result = [sanBiennial, sanWildcardBiennial]
      break
    case 3:
      result = [Triennial, sanWildcardTriennial]
      break
    case 4:
      result = [Quadrennial, sanWildcardQuadrennial]
      break
    case 5:
      result = [Quinquennial, sanWildcardQuinquennial]
      break
  }
  return result
}
// 格式化联系人
export const formatPerson = (person, personData) => {
  if (!personData) {
    for (let key in person) {
      if (person.hasOwnProperty(key)) {
        person[key] = ''
      }
    }
    return person
  }
  for (let key in person) {
    if (person.hasOwnProperty(key)) {
      person[key] = personData ? personData[key] : ''
    }
  }
  person.id = personData.ID
  person.email = personData.Email
  person.firstName = personData.Firstname
  person.lastName = personData.Lastname
  person.position = personData.Position
  person.tel = personData.Telephone
  return person
}
// 格式化公司/组织
export const formatCompany = (company, companyData) => {
  if (!companyData) {
    for (let key in company) {
      if (company.hasOwnProperty(key)) {
        company[key] = ''
      }
      company.country = 'CN'
    }
    return company
  }
  company.id = companyData.id
  company.tel = companyData.telephone
  company.city = companyData.locality
  company.companyName = companyData.name
  company.province = companyData.province
  company.postcode = companyData.postalCode
  company.address = companyData.registryAddr
  company.companyNumber = companyData.creditCode
  company.createDate = companyData.dateOfIncorporation
  return company
}
