<template>
  <div class="footer-group-container flex flex-between">
    <!-- SSL/TLS 证书 -->
    <div class="footer-group flex flex-start brands">
      <div class="cell" :class="{ 'footer-group-expand': selecteds.includes('brands') }" @click="handleSelect('brands')">
        <div class="title">{{ $t('vfooter').brand.title }}</div>
        <div class="flex flex-start">
          <ul class="group">
            <li
              v-for="brand in brands[0]"
              :key="brand.brand"
              class="item"
            >
              <router-link :to="`/brand${brand.brandPath}`">{{ brand.brand }}</router-link>
            </li>
            <li
              v-for="brand in brands[1]"
              :key="brand.brand"
              class="item"
            >
              <router-link :to="`/brand${brand.brandPath}`">{{ brand.brand }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 证书类型 -->
    <div class="footer-group flex flex-start brands">
      <div class="cell" :class="{ 'footer-group-expand': selecteds.includes('type') }" @click="handleSelect('type')">
        <div class="title">{{ $t('vfooter').type.title }}</div>
        <div class="flex flex-start">
          <ul class="group">
            <li
              v-for="item in $t('vfooter').type.options"
              :key="item.link"
              class="item"
            >
              <router-link :to="item.link">{{ item.label }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 数字签名 -->
    <div class="footer-group flex flex-start brands">
      <div class="cell" :class="{ 'footer-group-expand': selecteds.includes('sign') }" @click="handleSelect('sign')">
        <div class="title">{{ $t('vfooter').sign.title }}</div>
        <ul class="group">
          <li
            v-for="(item, index) in $t('vfooter').sign.options"
            :key="`footer-sign-${index}`"
            class="item"
          >
            <router-link :to="item.link">{{ item.label }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- 证书管理 -->
    <div class="footer-group flex flex-start">
      <div class="cell" :class="{ 'footer-group-expand': selecteds.includes('manage') }" @click="handleSelect('manage')">
        <div class="title">{{ $t('vfooter').mnage.title }}</div>
        <ul class="group">
          <li
            v-for="(item, index) in $t('vfooter').mnage.options"
            :key="`footer-manage-${index}`"
            class="item"
          >
            <router-link :to="item.link">{{ item.label }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- 解决方案 -->
    <!-- <div v-if="/zh/.test(locale)" class="footer-group flex flex-start">
      <div class="cell" :class="{ 'footer-group-expand': selecteds.includes('compliance') }" @click="handleSelect('compliance')">
        <div class="title">{{ $t('vfooter').solutions.title }}</div>
        <ul class="group">
          <li
            v-for="(item, index) in $t('vfooter').solutions.options"
            :key="`footer-solutions-${index}`"
            class="item"
          >
            <router-link :to="item.link">{{ item.label }}</router-link>
          </li>
        </ul>
      </div>
    </div> -->
    <!-- 关于我们 -->
    <!-- <div class="footer-group flex flex-start">
      <div class="cell" :class="{ 'footer-group-expand': selecteds.includes('about') }" @click="handleSelect('about')">
        <div class="title">{{ $t('vfooter').about.title }}</div>
        <ul class="group">
          <li
            v-for="(item, index) in $t('vfooter').about.options"
            :key="`footer-about-${index}`"
            class="item"
          >
            <router-link :to="item.link">{{ item.label }}</router-link>
          </li>
        </ul>
      </div>
    </div> -->
  </div>
</template>

<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import { formatNavbar } from '@/api/init'

export default defineComponent({
  setup(props, { root }) {
    const brands = computed(() => {
      const products = root.$store.state.products
      const brandList = formatNavbar(products)
      const len = brandList.length
      const data = [brandList.slice(0, len / 2), brandList.slice(len / 2)]
      return data
    })

    const locale = computed(() => root.$store.state.locale)

    const selecteds = ref([])
    const handleSelect = (key) => {
      if (selecteds.value.includes(key)) {
        selecteds.value = selecteds.value.filter(item => item !== key)
        return
      }
      selecteds.value.push(key)
    }
    return {
      brands,
      locale,
      selecteds,
      handleSelect
    }
  }
})
</script>

<style lang="less" scoped>
// #767D8E
.footer-group-container.flex {
  align-items: flex-start;
}
.title {
  // margin-bottom: 20px;
  margin-bottom: 12px;
  color: @border-color;
  font-size: large;
  font-weight: 400;
  .ri-arrow-up-s-line {
    display: none;
  }
}
.footer-group {
  max-width: 25%;
  &.brands {
    max-width: 50%;
  }
  .cell:not(:last-child) {
    margin-bottom: 12px;
  }
}
.item {
  margin-bottom: 12px;
  &:first-child {
    padding-top: 12px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
    // color: @text-color-l;
    transition: .2s ease;
  }
  a:hover {
    color: @theme-color;
  }
  span {
    color: #767D8E;
  }
}

@media screen and (max-width: 768px){
  .footer-group-container.flex {
    display: block;
  }
  .footer-group {
    width: 100%;
    max-width: 100%;
    margin-bottom: 12px;
    display: block;
    &.brands {
      max-width: 100%;
    }
    &.declare {
      .title {
        border: unset;
      }
      .group {
        height: auto;
        max-height: unset;
        width: 100%;
      }
    }
  }
  .group {
    height: 0;
    overflow: hidden;
    transition: .4s ease;
    max-height: 0;
    width: 100%;
    margin: 0;
  }
  .item {
    border-bottom: @border;
    padding-bottom: 12px;
    border-bottom-color: fade(#fff, 15);
  }
  .title {
    margin-bottom: 0;
    cursor: pointer;
    border-bottom: @border;
    padding-bottom: 12px;
    border-bottom-color: fade(#fff, 15);
    .ri-arrow-up-s-line {
      font-size: 18px;
      transition: .4s ease;
      display: inline-block;
    }
  }
  // selected
  .footer-group-expand {
    .title {
      margin-bottom: 12px;
      .ri-arrow-up-s-line {
        transform: rotate(180deg);
      }
    }
    .group {
      height: auto;
      max-height: 500px;
    }
  }
}
</style>
