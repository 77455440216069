export default {
  seo: {
    // 证书监控
    certhub: {
      title: '证书监控', // <title>证书监控</title>
      keywords: '证书监控,自动安装', // <meta name="keywords" content="证书监控">  ----> 多个关键字用英文逗号隔开
      description: '证书监控', // <meta name="description" content="证书监控">
    },
    // 首页 path -> /
    home: {
      title: '欢迎使用 Certhub 平台',
      keywords: '证书监控,自动安装',
      description: '证书监控'
    },
    // 关于我们 path -> /about-us
    about: {},
    // 联系我们 path -> /contact-us
    contact: {},
    // 合作伙伴
    partner: {},
    // 我们的客户 path -> /references
    references: {},
    // 银行信息以及付款方式 path -> /bank-details-and-payment-methods,
    wallet: {},
    // 品牌 path -> /brand/:brand
    brand: {},
    // SSL 类型[单域名、DV...] path -> /ssl/:sslType
    sslType: {},
    // TLS 类型[文档签名、代码签名...] path -> /digital-signing/:sslType
    sslType: {},
    // 产品详情
    product: {}
  }
}


/********************* 也可以主动向以下网站主动提交网站 **************************/
/**
 *
  360搜索引擎登录入口：http://info.so.360.cn/site_submit.html
  百度搜索网站登录口：http://www.baidu.com/search/url_submit.html
  百度单个网页提交入口：http://zhanzhang.baidu.com/sitesubmit
  Google网站登录口：https://www.google.com/webmasters/tools/submit-url?hl=zh-CN
  Google新闻网站内容：http://www.google.com/support/news_pub/bin/request.py?contact_type=suggest_content&hl=cn
  bing(必应)网页提交登录入口：http://www.bing.com/toolbox/submit-site-url
  搜狗网站收录提交入口:http://www.sogou.com/feedback/urlfeedback.php
  SOSO搜搜网站收录提交入口:http://www.soso.com/help/usb/urlsubmit.shtml
  雅虎中国网站登录口：http://search.help.cn.yahoo.com/h4_4.html
  网易有道搜索引擎登录口：http://tellbot.youdao.com/report
  MSN必应网站登录口：http://cn.bing.com/docs/submit.aspx?FORM=WSDD2
  Alexa网站登录入口：http://www.alexa.com/help/webmasters
  TOM搜索网站登录口：http://search.tom.com/tools/weblog/log.php
  铭万网B2B网址登陆口：http://search.b2b.cn/pageIncluded/AddPage.php
  蚁搜搜索网站登录口：http://www.antso.com/apply.asp
  快搜搜索网站登录口：http://www.kuaisou.com/main/inputweb.asp
  汕头搜索登录口：http://www.stsou.com/join.asp
  猎商登录口：http://www.lgoods.com/lg/lgss.htm
  天网网站登陆口：http://home.tianwang.com/denglu.htm
  速搜全球登陆口：http://www.suso.com.cn/suso/link.asp
  快搜网站登陆口：http://www.kuaisou.com/main/inputweb.asp
  搜猫搜索引擎登录入口：http://test.somao123.com/search/url_submit.php
  泽许搜索网站登录入口：http://www.zxyt.cn/home/add/
  简搜搜索引擎登陆口：http://www.jianso.com/add_site.html
 *
 */
