/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-04-26 14:07:03
 */
import Vue from 'vue'
import Vuex from 'vuex'
import VueCookie from 'vue-cookie'
import { formatNavbar, formatCertData, formatSingleCert } from '@/api/init'
Vue.use(Vuex)

const productsUrl = '/product_zh.json'

// eslint-disable-next-line
let products = require('@/static/language/product_zh.json')
let types = require('@/static/language/type_zh.json')
let brands = require('@/static/language/brand_zh.json')
const global = {
  locale: 'zh'
}
global.locale = VueCookie.get('locale')
if (!global.locale) {
  VueCookie.set('locale', 'zh')
  global.locale = VueCookie.get('locale')
}
products = require(`@/static/language/product_${global.locale}.json`)
types = require(`@/static/language/type_${global.locale}.json`)
brands = require(`@/static/language/brand_${global.locale}.json`)

// 筛选掉不显示的品牌
// const disabledBrands = ['bitcert', 'cfca', 'thawte', 'securesite', 'securesitepro', 'entrust', 'godaddy']
const disabledBrands = ['bitcert', 'thawte', 'securesite', 'securesitepro', 'entrust', 'godaddy']
brands = brands.filter(item => !disabledBrands.includes(item.alias))

// Blue background route
const transparentPaths = [
  '/',
  '/certhub',
  '/monitor',
  '/certhub/discovery',
  '/certhub/inventory',
  '/certhub/analyse',
  '/certhub/audit',
  '/certhub/monitor',
  '/certhub/log',
  '/compliance'
]
transparentPaths.forEach(item => {
  transparentPaths.push(`/en${item}`)
})

export const state = {
  locale: global.locale,
  navbarClassStyle: 'default',
  navbarFixed: false,
  products, // 所有产品
  types, // 所有类型
  brands, // 所有品牌
  navbarData: formatNavbar(products),
  navbarCertData: formatCertData(products),
  navbarSingleData: formatSingleCert(products),
  productsUrl,
  transparentPaths
}

export const mutations = {
  // 切换语言
  changeLocale(state, locale) {
    global.locale = locale
    state.locale = locale
    localStorage.setItem('locale', locale)
  },
  setNavbarClassStyle (state, styleName) {
    if (!styleName) return
    state.navbarClassStyle = styleName
  },
  setNavbarFixed (state, isFixed) {
    state.navbarFixed = isFixed
  },
  // 获取品牌列表
  getBrands() {},
  getAllProducts (state, products) {
    state.products = products
  },
  getAllTypes (state, types) {
    state.types = types
  },
  genNavbar (state, navbarData) {
    state.navbarData = navbarData
  },
  genNavbarCertData (state, navbarCertData) {
    state.navbarCertData = navbarCertData
  },
  genNavbarSingleData (state, navbarSingleData) {
    state.navbarSingleData = navbarSingleData
  }
}

const store = new Vuex.Store({
  state,
  mutations,
  getters: {
    brands: (s) => s.brands,
    products: (s) => s.products,
  }
})

export default store
