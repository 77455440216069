// 证书品牌
const brand = {
  title: 'By The CA'
}

// 验证类型
const validation = {
  title: 'By Validation',
  options: [
    {
      label: 'Domain Validation (DV)',
      link: '/ssl/domain-validation'
    },
    {
      label: 'Organization Validation (OV)',
      link: '/ssl/organization-validation'
    },
    {
      label: 'Extended Validation (EV)',
      link: '/ssl/extended-validation'
    },
  ]
}

// 证书类型
const type = {
  title: 'By Type',
  options: [
    {
      label: 'Single Domain',
      link: '/ssl/single-domain'
    },
    {
      label: 'Multi Domains',
      link: '/ssl/multi-domain'
    },
    {
      label: 'Wildcard',
      link: '/ssl/wildcard'
    },
    {
      label: 'Public IP Address',
      link: '/ssl/public-ip-address'
    },
    {
      label: 'Flex TLS',
      link: '/ssl/flex-tls'
    },
    {
      label: 'China OCSP TLS ',
      link: '/ssl/china-optimized-ocsp-tls'
    }
  ]
}

// 数字签名
const sign = [
  {
    icon: '/images/navbar/icon-code-signing.svg',
    title: 'Standard Code Signing',
    link: '/digital-signing/code-signing',
    desc: 'Add digital signature to applications'
  },
  {
    icon: '/images/navbar/icon-ev-code-signing.svg',
    title: 'EV Code Signing',
    link: '/digital-signing/ev-code-signing',
    desc: 'Instant SmartScreen reputation and sign driver'
  },
  {
    icon: '/images/navbar/icon-email-signing.svg',
    title: 'Email Signing',
    link: '/digital-signing/email-signing',
    desc: 'Sign and encrypt emails'
  },
  {
    icon: '/images/navbar/icon-document-signing.svg',
    title: '文件签名证书',
    link: '/digital-signing/document-signing',
    desc: 'Displaying the certificate in the application'
  }
]

// 工具
const tools = [
  {
    title: 'Certificate Tools',
    desc: '免费的 TLS 证书工具',
    options: [
      [
        {
          label: 'CSR Generator',
          link: '/tools/gen-csr'
        },
        {
          label: 'CSR Decoder',
          link: '/tools/parse-csr'
        },
        {
          label: 'Certificate Decoder',
          link: '/tools/parse-cert'
        }
      ],
      [
        {
          label: 'Certificate Key Matcher',
          link: '/tools/match'
        },
        {
          label: 'Certificate Format Converter',
          link: '/tools/convert-cert'
        },
        {
          label: 'TLS Configuration Generator',
          link: '/tools/ssl-config'
        }
      ],
    ]
  },
  {
    title: 'Certificate Management',
    desc: 'Certificate Lifecycle Automation',
    options: [
    {
      label: 'Certificate Tools',
      link: '/tools/parse-cert'
    },
    {
      label: 'Certificate Discovery',
      link: '/certhub/discovery'
    },
    {
      label: 'Certificate Inventory Tool',
      link: '/certhub/inventory'
    },
    {
      label: 'Certificate Analysis Tool',
      link: '/certhub/analyse'
    },
    {
      label: 'Certificate Audit',
      link: '/certhub/audit'
    },
    {
      label: 'Certificate Monitor',
      link: '/certhub/monitor'
    },
    {
      label: 'Certificate Transparency Search',
      link: '/certhub/log'
    }
  ]
  }
]

// 解决方案
const compliance = [
  {
    title: 'Solutions',
    options: [
      {
        label: 'Compliance',
        link: '/compliance'
      },
      {
        label: 'Compliance Evaluation',
        link: '/evaluation'
      },
      {
        label: 'Public Cloud',
        link: '/public-cloud'
      },
    ]
  },
  {
    options: [
      {
        label: 'LEI',
        link: '/lei'
      },
      {
        label: 'PKI-as-a-Service (PKIaaS)',
        link: '/pkiaas'
      },
      {
        label: 'Digital Signing Service',
        link: '/digitalsigningservice'
      },
    ]
  },
  {
    options: [
      {
        label: 'IoT Certificates',
        link: '/aot-tls'
      },
      {
        label: 'Trusted Websites',
        link: '/tca'
      },
      {
        label: 'Client Certificates',
        link: '/s-mime-solutions'
      },
    ]
  },
]

// 合作伙伴
const partner = [
  {
    icon: '/images/navbar/enterprise.svg',
    title: 'Enterprise',
    desc: 'Work together to create our next win-win situation',
    link: '/partner/enterprise'
  },
  {
    icon: '/images/navbar/reseller.svg',
    title: 'Reseller',
    desc: 'Get wholesale discount rates up to 90% off MSRP',
    link: '/partner/reseller'
  },
  {
    icon: '/images/navbar/affiliate.svg',
    title: 'Affiliates',
    desc: 'Convert qualified traffic into commissions.',
    link: '/partner/affiliate'
  },
  {
    icon: '/images/navbar/member.svg',
    title: 'Member Discount',
    desc: 'Enjoy auto discounts',
    link: '/partner/member'
  },
]

// 联系我们
const contact = {
  tel: 'Telephone',
  contactNow: 'Contact Now',
  contactUs: 'Contact Us',
  serviceTime: 'Service Time: 9:00～18:00 7 Days a Week',
  serviceTel: ['400-128-0988', '177-0811-9188'],
  aboutUs: 'About Us',
  slogan: 'Top SSL//PKI Provider',
  callback: 'Address:',
  notWait: 'Suite 1503, 15/F Carnival Comm BLDG 18 Java RD North Point, Hong Kong, China',
  chatNow: 'Live Chat',
  chatToTeam: 'SSL Specialists Available 24/7 to Assist.'
}

export default {
  nnavbar: {
    sign,
    type,
    tools,
    brand,
    contact,
    partner,
    compliance,
    validation
  }
}