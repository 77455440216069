<template>
  <div v-if="$i18n.locale === 'en'" class="tls-mark-component">
    <GlobalSignCodeSigning v-if="productName === 'GlobalSignCodeSigning'"/>
    <SectigoCodeSigning v-if="productName === 'SectigoCodeSigning'"/>
    <SectigoEVCodeSigning v-if="productName === 'SectigoEVCodeSigning'"/>
    <GlobalSignEVCodeSigning v-if="productName === 'GlobalSignEVCodeSigning'"/>
    <DigiCertEVCodeSigning v-if="productName === 'DigiCertEVCodeSigning'"/>
    <DigiCertDocumentSigningOrg2000 v-if="productName === 'DigiCertDocumentSigningOrg.2000'"/>
    <DigiCertDocumentSigningOrg5000 v-if="productName === 'DigiCertDocumentSigningOrg.5000'"/>
    <DigiCertCodeSigning v-if="productName === 'DigiCertCodeSigning'"/>
    <BitCertBasicEmailSigning v-if="productName === 'BitCertBasicEmailSigning'"/>
  </div>
</template>

<script>
import GlobalSignCodeSigning from './Mark/GlobalSignCodeSigning.md'
import SectigoCodeSigning from './Mark/SectigoCodeSigning.md'
import SectigoEVCodeSigning from './Mark/SectigoEVCodeSigning.md'
import GlobalSignEVCodeSigning from './Mark/GlobalSignEVCodeSigning.md'
import DigiCertEVCodeSigning from './Mark/DigiCertEVCodeSigning.md'
import DigiCertDocumentSigningOrg2000 from './Mark/DigiCertDocumentSigningOrg2000.md'
import DigiCertDocumentSigningOrg5000 from './Mark/DigiCertDocumentSigningOrg5000.md'
import DigiCertCodeSigning from './Mark/DigiCertCodeSigning.md'
import BitCertBasicEmailSigning from './Mark/BitCertBasicEmailSigning.md'

export default {
  name: 'TlsMarkComponent',
  components: {
    GlobalSignCodeSigning,
    SectigoCodeSigning,
    SectigoEVCodeSigning,
    GlobalSignEVCodeSigning,
    DigiCertEVCodeSigning,
    DigiCertDocumentSigningOrg2000,
    DigiCertDocumentSigningOrg5000,
    DigiCertCodeSigning,
    BitCertBasicEmailSigning
  },
  props: {
    product: Object
  },
  computed: {
    productName () {
      if (!this.product) return
      return String(this.product.productName).replace(/[\s]/g, '')
    }
  }
}
</script>

<style lang="less" scoped>
.tls-mark-component {
  padding: 48px 0;
  & /deep/ h3 {
    color: #1652f0;
    font-size: 18px;
    margin-bottom: 12px;
  }
  & /deep/ p {
    line-height: 1.5em;
    margin-bottom: 24px;
  }
}
</style>
