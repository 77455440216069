<template>
  <!-- about summary group -->
  <div class="article-media" :class="[`layout-${layout || 'start'}`, { 'none-img': !src }]">
    <div v-if="src" class="img-container">
      <img :src="src" :alt="alt" :draggable="false" />
    </div>
    <div class="article-media-content">
      <slot name="title">
        <h4 class="title">{{ title }}</h4>
      </slot>
      <slot></slot>
    </div>
  </div>
</template>

<script>
// import { scrollMixin } from '@/utils'

export default {
  name: 'ArticleMedia',
  // mixins: [scrollMixin],
  props: {
    src: String,
    alt: String,
    title: String,
    layout: {
      type: String,
      default: 'start' // start | end
    }
  },
  data() {
    return {
      scrollAnimateList: [
        { el: '.article-media' }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.article-media {
  padding: 48px 0;
  display: flex;
  align-items: center;
  // border-top: @border;
  max-width: 1240px;
  margin: 0 auto;
  p {
    line-height: 1.5em;
  }
  img {
    // width: 214px;
    display: block;
    width: 100%;
    max-width: 350px;
    // background-color: @bg-color;
    margin-right: 48px;
    border-radius: 8px;
  }
  .img-container {
    min-height: 150px;
    width: 50%;
    display: flex;
    align-items: center;
  }
  // no img style
  &.layout-start {
    flex-direction: row;
  }
  &.layout-end {
    flex-direction: row-reverse;
    img {
      margin-right: 0;
      margin-left: 48px;
    }
  }
  .article-media-content {
    width: calc(100% - 214px - 48px);
    line-height: 1.625;
    font-size: 16px;
  }
  &.none-img {
    .article-media-content {
      width: 100%;
    }
  }
  .title {
    color: initial;
    text-align: left;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 768px){
  .article-media {
    flex-direction: column;
    &.layout-start {
      flex-direction: column-reverse;
      // flex-direction: column;
      img {
        margin-right: 0;
      }
    }
    &.layout-end {
      flex-direction: column-reverse;
      .article-media-content {
        margin-bottom: 0;
      }
      img {
        margin-left: 0;
        margin-bottom: 24px;
      }
    }
    img,
    .article-media-content {
      width: 100%;
    }
    .article-media-content {
      margin-bottom: 24px;
    }
    &.none-img {
      .article-media-content {
          margin-bottom: 0;
      }
    }
  }
}
</style>
