/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-05-16 10:24:59
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-06-02 10:01:30
 */
import { page } from '../utils'

const protocolRoute = [
  {
    path: '/terms',
    component: page('terms'),
    meta: {
      title: '服务条款'
    }
  },
  {
    path: '/privacy-policy',
    component: page('privacy-policy'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/member-aggreement',
    component: page('member-aggreement'),
    meta: {
      title: '注册协议'
    }
  },
  {
    path: '/refund-policy',
    component: page('refund-policy'),
    meta: {
      title: '退款协议'
    }
  },
]

export default protocolRoute
