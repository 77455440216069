/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-04-29 09:56:00
 */
import Vue from 'vue'
import * as filters from './filters'

Object.keys(filters).map(key => {
  Vue.filter(key, filters[key])
})
