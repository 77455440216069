/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:26:00
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-05-30 09:44:11
 */
import VueRouter from 'vue-router'
import Vue from 'vue'
import routes from './modules'
import packageJson from '../../package.json'

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // base: process.env.NODE_ENV === 'development' ? '/' : `v${packageJson.version}_${process.env.NODE_ENV}`,
  routes,
  linkActiveClass: 'is-active',
  scrollBehavior() {
    return { x: 0, y: 0 } 
  }
})

export default router
