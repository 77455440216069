export default {
  "createCSR": {
    "pageTitle": "CSR Generator",
    "title": "CSR Generator",
    "subtitle": "Generate a Certificate Signing Request in seconds!",
    "summary": [
      "Secure and easy Certificate Signing Request generation for your SSL certificates",
      "All actions are done by client side, don't worry about the private key, no one will know it"
    ]
  }
}
