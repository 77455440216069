<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-04-28 14:16:38
-->
<template>
  <div class="country-select">
    <el-select
      v-model="selected"
      :loading="loading"
      :disabled="disabled"
      value=""
      filterable
      popper-class="country-select-options"
      auto-complete="new-password"
    >
      <el-option
        v-for="country in countries"
        :key="country.countryCode"
        :value="country.countryCode"
        :label="`${country.countryName}（${country.countryCode}）`"
      />
    </el-select>
  </div>
</template>

<script>
import { ref, watch, computed, onBeforeUnmount } from '@vue/composition-api'
import axios from 'axios'

export default {
  name: 'CountrySelect',
  model: {
    prop: 'country',
    event: 'change'
  },
  props: {
    country: String,
    disabled: Boolean
  },
  setup (props, ctx) {
    const loading = ref(true)
    const countries = ref([])
    const selected = ref(props.country)
    const local = computed(() => ctx.root.$store.state.locale)
    getCountries(local.value).then((res) => {
      loading.value = false
      countries.value = res
    })
    watch(() => selected.value, (val) => {
      ctx.emit('change', val)
    })

    onBeforeUnmount(() => {
      loading.value = false
    })
    return {
      loading,
      selected,
      countries
    }
  }
}

// 获取列表
async function getCountries(local) {
  const res = await axios.get(`/language/country_${local === 'zh' ? local : 'en'}.json`)
  return res.data.data
}
</script>

<style>
.country-select .el-select {
  width: 100%;
  max-width: 300px;
}
.country-select-options {
  max-width: 300px;
}
</style>
