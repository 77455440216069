<template>
  <div class="topbar">
    <div class="container flex flex-between">
      <div class="flex mobile-hide">
        <a href="tel:4001280988" class="p-10 fw-600">电话 400-128-0988</a>
        <a href="email:support@certerassl.com" class="p-10 fw-600">邮箱 support@certbase.com</a>
      </div>
      <div class="flex">
        <router-link to="/about-us" class="p-10 fw-600 mobile-hide">关于我们</router-link>
        <router-link to="/contact-us" class="p-10 fw-600 mobile-hide">联系我们</router-link>
        <a :href="VUE_APP_TARGET" class="p-10 fw-600">注册</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Topbar"
}
</script>

<style lang="less" scoped>
.topbar {
  border-bottom: 1px solid #dee2e6;
  color: #65697f;
}
@media screen and (max-width: 768px) {
  .topbar .container {
    justify-content: flex-end;
  }
}
</style>
