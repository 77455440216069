<template>
  <div class="container flex flex-between py-15">
    <Logo custom-style="height: 38px;"/>
    <div class="flex menu mobile-hide">
      <router-link to="/ssl-certificates-price" class="p-10 fw-600 mr-10">购买证书</router-link>
      <tippy
        placement="bottom"
        trigger="click"
        theme="light"
        :onShow="() => onTippyShow('ssl')"
        :onHide="onTippyHide"
      >
        <template v-slot:trigger>
          <div class="p-10 ml-10 fw-600 mr-10 menu-hover">
            数字证书
            <span :class="{ 'is-show': showName === 'ssl' }" class="menu-icon">
              <i class="ri-arrow-down-s-line"></i>
            </span>
          </div>
        </template>
        <div class="menu-modal brand-modal">
          <div class="container">
            <div class="menu-modal__body flex flex-between">
              <ul>
                <li class="ssl-title">证书品牌</li>
                <li class="ssl-item">
                  <router-link :to="`/brand/digicert`">Digicert</router-link>
                </li>
                <li class="ssl-item">
                  <router-link :to="`/brand/geotrust`">GeoTrust</router-link>
                </li>
                <!-- <li class="ssl-item">
                  <router-link :to="`/brand/thawte`">Thawte</router-link>
                </li> -->
                <li class="ssl-item">
                  <router-link :to="`/brand/rapidssl`">RapidSSL</router-link>
                </li>
                <li class="ssl-item">
                  <router-link :to="`/brand/globalsign`">GlobalSign</router-link>
                </li>
                <li class="ssl-item">
                  <router-link :to="`/brand/sectigo`">Sectigo</router-link>
                </li>
                <li class="ssl-item">
                  <router-link :to="`/brand/alphassl`">AlphaSSL</router-link>
                </li>
                <li class="ssl-item">
                  <router-link :to="`/brand/certum`">Certum</router-link>
                </li>
                <li class="ssl-item">
                  <router-link :to="`/brand/positivessl`">PositiveSSL</router-link>
                </li>
              </ul>
              <ul>
                <li class="ssl-title">证书类型</li>
                <li class="ssl-item">
                  <router-link :to="`/multi-domain-ssl-certificates`">弹性 TLS 证书</router-link>
                </li>
                <li class="ssl-item">
                  <router-link :to="`/single-domain-ssl-certificates`">单域名 TLS 证书</router-link>
                </li>
                <li class="ssl-item">
                  <router-link :to="`/wildcard-ssl-certificates`">通配符 TLS 证书</router-link>
                </li>
                <li class="ssl-item">
                  <router-link :to="`/domain-validation-certificates`">域名级（DV）TLS 证书</router-link>
                </li>
                <li class="ssl-item">
                  <router-link :to="`/organization-validation-certificates`">企业级（OV）TLS 证书</router-link>
                </li>
                <li class="ssl-item">
                  <router-link :to="`/ev-ssl-certificates`">增强级（EV）TLS 证书</router-link>
                </li>
                <li class="ssl-item">中国 OCSP TLS 证书</li>
                <li class="ssl-item">公网 IP 地址 TLS 证书</li>
              </ul>
              <ul>
                <li class="ssl-title">文件、代码签名证书</li>
                <li class="ssl-item">文件签名证书</li>
                <li class="ssl-item">
                  <router-link :to="`/code-signing-certificates`">企业级（OV）代码签名</router-link>
                </li>
                <li class="ssl-item">
                  <router-link :to="`/code-signing-certificates`">增强级（EV）代码签名</router-link>
                </li>
              </ul>
              <ul>
                <li class="ssl-title">邮件签名</li>
                <li class="ssl-item">邮件标记证书</li>
                <li class="ssl-item">邮件签名证书（S/MIME）</li>
              </ul>
            </div>
          </div>
        </div>
      </tippy>
      <!-- <div class="p-10 fw-600 p-rel menu-hover">
        产品
        <i class="ri-arrow-down-s-line"></i>
        <ul class="menu-modal p-abs">
          <li
            v-for="(item, index) in products"
            :key="index"
          >
            <router-link :to="item.href" class="flex flex-center flex-column">
              <img style="width: 40px;" class="mb-5" :src="item.icon" alt="">
              {{ item.label }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="p-10 fw-600 p-rel menu-hover">
        代码签名
        <i class="ri-arrow-down-s-line"></i>
        <ul class="menu-modal p-abs">
          <li class="">
            <router-link to="/" class="flex flex-center flex-column">代码签名</router-link>
          </li>
          <li class="">
            <router-link to="/" class="flex flex-center flex-column">代码签名</router-link>
          </li>
          <li class="">
            <router-link to="/" class="flex flex-center flex-column">代码签名</router-link>
          </li>
          <li class="">
            <router-link to="/" class="flex flex-center flex-column">代码签名</router-link>
          </li>
          <li class="">
            <router-link to="/" class="flex flex-center flex-column">代码签名</router-link>
          </li>
          <li class="">
            <router-link to="/" class="flex flex-center flex-column">代码签名</router-link>
          </li>
        </ul>
      </div> -->
      <!-- <tippy
        placement="bottom"
        trigger="click"
        theme="light"
        :onShow="() => onTippyShow('service')"
        :onHide="onTippyHide"
      >
        <template v-slot:trigger>
          <div class="p-10 mr-10 fw-600 p-rel menu-hover">
            证书服务
            <span :class="{ 'is-show': showName === 'service' }" class="menu-icon">
              <i :class="{ 'is-show': showName === 'service' }" class="ri-arrow-down-s-line"></i>
            </span>
          </div>
        </template>
        <div class="menu-modal brand-modal">
          <div class="container">
            <div class="menu-modal__body flex flex-between">
              <ul>
                <li class="ssl-title">网站流量</li>
                <li class="ssl-item">Digicert</li>
              </ul>
              <ul>
                <li class="ssl-title">软件代码</li>
                <li class="ssl-item">弹性 TLS 证书</li>
              </ul>
              <ul>
                <li class="ssl-title">数据安全</li>
                <li class="ssl-item">文件签名证书</li>
              </ul>
              <ul>
                <li class="ssl-title">身份信息</li>
                <li class="ssl-item">邮件标记证书</li>
              </ul>
            </div>
          </div>
        </div>
      </tippy> -->
      <router-link to="/partner" class="p-10 fw-600 mr-10">合作伙伴</router-link>
      <!-- <router-link to="/tools" class="p-10 fw-600 mr-20">证书工具</router-link> -->
      <tippy
        placement="bottom"
        trigger="click"
        theme="light"
        :onShow="() => onTippyShow('service')"
        :onHide="onTippyHide"
      >
        <template v-slot:trigger>
          <div class="p-10 mr-20 fw-600 p-rel menu-hover">
            证书工具
            <span :class="{ 'is-show': showName === 'tools' }" class="menu-icon">
              <i :class="{ 'is-show': showName === 'tools' }" class="ri-arrow-down-s-line"></i>
            </span>
          </div>
        </template>
        <div class="menu-modal brand-modal">
          <div class="container">
            <div class="menu-modal__body flex flex-between">
              <ul>
                <li class="ssl-item">
                  <router-link to="/tools/gen-csr">创建 CSR</router-link>
                </li>
                <li class="ssl-item">
                  <router-link to="/tools/parse-csr">解析 CSR</router-link>
                </li>
              </ul>
              <ul>
                <li class="ssl-item">
                  <router-link to="/tools/parse-cert">解析证书</router-link>
                </li>
                <li class="ssl-item">
                  <router-link to="/tools/match">公私钥匹配</router-link>
                </li>
              </ul>
              <ul>
                <li class="ssl-item">
                  <router-link to="/tools/convert-cert">证书格式转换</router-link>
                </li>
                <li class="ssl-item">
                  <router-link to="/tools/ssl-config">TLS 配置生成器</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </tippy>
      <CButton type="primary">登录</CButton>
    </div>
    <div class="mobile-show-block">
      <CButton :click="true" type="primary" @click="showMobileMenu = !showMobileMenu">
        <i class="ri-menu-line"></i>
      </CButton>
    </div>
    <div :class="{'is-show': showMobileMenu }" class="mobile-menu">
      <div class="container">
        <div class="flex flex-between py-10">
          <Logo custom-style="height: 28px;"/>
          <i class="ri-close-line" @click="showMobileMenu = false"></i>
        </div>
      </div>
      <!-- 手机导航 -->
      <div class="mobile-menu-container">
        <!-- 价格 -->
        <a href="" class="fw-600 mobile-menu-item">价格</a>
        <!-- 品牌 -->
        <a href="javascript:void(0)" class="fw-600 menu-hover" @click="expandMenu('brand')">
          <div class="flex flex-between mobile-menu-item">
            品牌
            <i :class="`ri-${isExpand('brand') ? 'subtract' : 'add'}-line`"></i>
          </div>
          <ul v-show="isExpand('brand')" class="mobile-menu-modal">
            <li
              v-for="(item, index) in brands"
              :key="index"
            >
              <a href="" class="flex flex-center flex-column mobile-menu-item">
                {{ item.title }}
              </a>
            </li>
          </ul>
        </a>
        <!-- 产品 -->
        <a href="javascript:void(0)" class="fw-600 menu-hover" @click="expandMenu('product')">
          <div class="flex flex-between mobile-menu-item">
            产品
            <i :class="`ri-${isExpand('product') ? 'subtract' : 'add'}-line`"></i>
          </div>
          <ul v-show="isExpand('product')" class="mobile-menu-modal">
            <li
              v-for="(item, index) in products" :key="index"
            >
              <a href="" class="flex flex-center flex-column mobile-menu-item">
                {{ item.label }}
              </a>
            </li>
          </ul>
        </a>
        <!-- 代码签名 -->
        <a href="javascript:void(0)" class="fw-600 menu-hover" @click="expandMenu('code')">
          <div class="flex flex-between mobile-menu-item">
            代码签名
            <i :class="`ri-${isExpand('code') ? 'subtract' : 'add'}-line`"></i>
          </div>
          <ul v-show="isExpand('code')" class="mobile-menu-modal">
            <li
              v-for="(item, index) in codes" :key="index"
            >
              <a href="" class="flex flex-center flex-column mobile-menu-item">
                {{ item.label }}
              </a>
            </li>
          </ul>
        </a>
        <!-- 证书服务 -->
        <a href="javascript:void(0)" class="fw-600 menu-hover" @click="expandMenu('service')">
          <div class="flex flex-between mobile-menu-item">
            证书服务
            <i :class="`ri-${isExpand('service') ? 'subtract' : 'add'}-line`"></i>
          </div>
          <ul v-show="isExpand('service')" class="mobile-menu-modal">
            <li
              v-for="(item, index) in services"
              :key="index"
            >
              <a href="" class="flex flex-center flex-column mobile-menu-item">
                {{ item.label }}
              </a>
            </li>
          </ul>
        </a>
        <!-- 合作伙伴 -->
        <a href="" class="fw-600 mobile-menu-item">合作伙伴</a>
      </div>
    </div>
    <!-- <div class="menu-modal brand-modal">
      <div class="container">
        <div class="menu-modal__body flex flex-between">
          <ul>
            <li class="ssl-title">证书品牌</li>
            <li class="ssl-item">Digicert</li>
            <li class="ssl-item">GeoTrust</li>
            <li class="ssl-item">Thawte</li>
            <li class="ssl-item">RapidSSL</li>
            <li class="ssl-item">GlobalSign</li>
            <li class="ssl-item">Sectigo</li>
            <li class="ssl-item">AlphaSSL</li>
            <li class="ssl-item">Certum</li>
            <li class="ssl-item">PositiveSSL</li>
          </ul>
          <ul>
            <li class="ssl-title">证书类型</li>
            <li class="ssl-item">弹性 TLS 证书</li>
            <li class="ssl-item">单域名 TLS 证书</li>
            <li class="ssl-item">通配符 TLS 证书</li>
            <li class="ssl-item">域名级（DV）TLS 证书</li>
            <li class="ssl-item">企业级（OV）TLS 证书</li>
            <li class="ssl-item">增强级（EV）TLS 证书</li>
            <li class="ssl-item">中国 OCSP TLS 证书</li>
            <li class="ssl-item">公网 IP 地址 TLS 证书</li>
          </ul>
          <ul>
            <li class="ssl-title">TLS 签名证书</li>
            <li class="ssl-item">文件签名证书</li>
            <li class="ssl-item">企业级（OV）代码签名</li>
            <li class="ssl-item">增强级（EV）代码签名</li>
          </ul>
          <ul>
            <li class="ssl-title">邮件签名</li>
            <li class="ssl-item">邮件标记证书</li>
            <li class="ssl-item">邮件签名证书（S/MIME）</li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CButton from '../CButton.vue';
import Logo from '../Logo.vue';

export default {
  name: "NavMenu",
  components: {
    Logo,
    CButton,
  },
  data() {
    return {
      showMenu: false,
      showMobileMenu: false,
      expandMenus: [],
      showName: '',
      products: [
        { label: '域名级 (DV)', href: '/domain-validation-certificates', icon: '/icon/dv-menu-icon.svg' },
        { label: '企业级 (OV)', href: '/organization-validation-certificates', icon: '/icon/ov-menu-icon.svg' },
        { label: '增强级 (EV)', href: '/ev-ssl-certificates', icon: '/icon/ev-menu-icon.svg' },
        { label: '通配符 SSL/TLS', href: '/wildcard-ssl-certificates', icon: '/icon/wildcard-menu-icon.svg' },
        { label: '弹性 SSL/TLS', href: '/multi-domain-ssl-certificates', icon: '/icon/multidomain-menu-icon.svg' },
        { label: '代码签名 TLS', href: '/code-signing-certificates', icon: '/icon/codesign-menu-icon.svg' },
      ],
      codes: [],
      services: [
        { label: '网站流量', href: '/website-traffic' },
        { label: '软件代码', href: '/software-code' },
        { label: '数据安全', href: '/data-security' },
        { label: '身份信息', href: '/identification' },
      ],
    }
  },
  computed: mapGetters(['brands']),
  methods: {
    onTippyShow(name) {
      this.showName = name;
    },
    onTippyHide() {
      this.showName = '';
    },
    isExpand(menuName = '') {
      return this.expandMenus.includes(menuName);
    },
    expandMenu(menuName = '') {
      const menuindex = this.expandMenus.findIndex(item => item === menuName);
      if (menuindex < 0) {
        this.expandMenus.push(menuName);
        return;
      }
      this.expandMenus.splice(menuindex, 1);
    }
  }
}
</script>

<style>
.tippy-popper {
  max-width: 100% !important;
  /* transform: none !important; */
  transform: translate3d(0px, 108px, 0px) !important;
  pointer-events: all !important;
}
.tippy-tooltip {
  max-width: 100% !important;
  padding: 0 !important;
}
.tippy-tooltip.light-theme {
  box-shadow: 0 10px 10px rgba(0, 0, 0, .1) !important;
  border-radius: 0 !important;
}
</style>
<style lang="less" scoped>
img {
  display: block;
}
.menu {
  & > a {
    position: relative;
  }
  & > a:not(.btn)::before {
    content: "";
    // visibility: hidden;
    position: absolute;
    height: 2px;
    width: 0;
    bottom: -2px;
    left: 0;
    background-color: var(--theme-color);
    transition: .3s ease;
  }
  & > a:hover::before {
    visibility: visible;
    width: 100%;
  }
}
.menu-icon {
  transition: .3s ease;
  display: block;
  &.is-show {
    transform: rotate(180deg);
    transform-origin: 50% 45%;
  }
}
.menu-modal {
  // position: fixed;
  // position: absolute;
  // top: 40px;
  // top: 117px;
  // left: 0;
  z-index: 999;
  // transform: translateX(-40%);
  // box-shadow: 0 10px 30px 0 rgba(0,0,0,.1);
  box-shadow: 0 30px 30px 0 rgba(0,0,0,.1);
  // border-top: solid 2px var(--theme-color);
  border-top: solid 1px #dde1e7;
  // width: 501px;
  width: 100vw;
  background: #fff;
  border-left: solid 1px #e7eaec;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  // visibility: hidden;
  // visibility: visible;
  // opacity: 0;
  // opacity: 1;
  transition: .3s ease-in-out;
  border-left: solid 1px #e7eaec;
  border-right: solid 1px #e7eaec;
  li {
    // width: 33.3333%;
    // border-left: solid 1px #e7eaec;
    // border-bottom: solid 1px #e7eaec;
    // &:hover {
    //   background-color: #f5f7fa;
    // }
    text-align: left;
    a {
      height: 100px;
    }
    img {
      width: 100%;
      display: block;
    }
  }
  .ssl-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .ssl-item {
    color: #707a8a;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-top: 6px;
  }
}

.menu-modal__body {
  padding: 20px;
  align-items: flex-start;
}
.service-modal li {
  width: 50%;
}
.brand-modal li {
  a {
    height: 70px;
  }
}
.menu-hover {
  cursor: pointer;
  position: relative;
  display: flex;
  &:hover {
    .menu-modal {
      visibility: visible;
      opacity: 1;
    }
  }
}

// 手机菜单
.mobile-menu {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  z-index: 999;
  background-color: #002856;
  min-height: 100vh;
  color: #fff;
  opacity: 0;
  transition: .3s ease;
  &.is-show {
    left: 0;
    opacity: 1;
  }
  .ri-close-line {
    font-size: 36px;
  }
}
.mobile-menu-container {
  a {
    display: block;
    user-select: none;
  }
  .mobile-menu-item {
    border-bottom: 1px solid rgba(255,255,255,.1);
    padding: 15px 30px;
  }
  [class*="ri-"] {
    font-size: 18px;
  }
}
.mobile-menu-modal {
  background-color: rgba(255,255,255,.07);
}
</style>
