<template>
  <div
    class="between-article"
    :class="{
      'google-style': googleStyle,
      'is-full': isFull
    }
    ">
    <div
      v-for="(row, index) in article"
      :key="`between-article__row-${index}`"
      class="between-article__row"
      :class="{ 'is-img': (row[0] && row[0].img) || (row[1] && row[1].img) }">
      <div
        v-for="(col, k) in row"
        :key="`between-article__${k}-${index}`"
        class="between-article__col">
        <template v-if="!col.img">
          <h4 class="between-article__title">{{ col.title }}</h4>
          <section class="between-article__content">
            <p v-for="(p, j) in col.content" :key="`article__p-${j}-${k}`">{{ p }}</p>
          </section>
          <a
            v-if="googleStyle && col.link"
            :href="col.link.href"
            class="between-article__link link-style"
          >
            {{ col.link.text }}
          </a>
        </template>
        <template v-else>
          <img :src="col.img" class="between-article__img" alt="" :draggable="false"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import { scrollMixin } from '@/utils'

export default {
  name: 'BetweenArticle',
  // mixins: [scrollMixin],
  props: {
    googleStyle: Boolean,
    isFull: Boolean,
    article: {
      type: Array,
      default: () => [
        [
          { title: '', content: [], img: '' }
        ]
      ]
    }
  },
  data() {
    return {
      scrollAnimateList: [
        { el: '.between-article__title' },
        { el: '.between-article__content', options: { delay: 300 } },
        { el: '.between-article__link', options: { delay: 400 } },
        { el: '.between-article__col > img', options: { delay: 500 } },
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.google-style {
  max-width: 800px;
  margin: 0 auto;
  .between-article__title {
    text-align: left;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .between-article__row {
    justify-content: center;
  }
  .between-article__content {
    color: #5f6368;
    line-height: 1.625;
    font-size: 16px;
  }
  .link-style {
    margin-top: 22px;
    display: inline-block;
  }
  &.is-full {
    max-width: 100%;
    .between-article__img {
      max-width: 100%;
    }
    .between-article__content {
      font-size: 22px;
      line-height: 34px;
      color: initials;
    }
    .between-article__title {
      font-weight: 400;
    }
  }
}
.between-article__title {
  font-size: 22px;
  margin-bottom: 12px;
  text-align: center;
}
.between-article__content {
  line-height: 1.5em;
  p:not(:last-child) {
    margin-bottom: 12px;
  }
}
.between-article__img {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  display: block;
}
.between-article__row {
  display: flex;
  justify-content: space-between;
  padding: 48px 0;
  &.is-img {
    align-items: center;
  }
  &:first-child {
    padding-top: 0;
  }
  &:not(:last-child) {
    border-bottom: @border;
  }
  .between-article__col:first-child {
    margin-right: 24px;
  }
  .between-article__col:last-child {
    margin-left: 24px;
  }
}
.between-article__col {
  flex: 1;
}

@media screen and (max-width: 768px){
  .between-article__row {
    display: block;
    .between-article__col:first-child {
      margin-right: 0;
      margin-bottom: 24px;
    }
    .between-article__col:last-child {
      margin-left: 0;
    }
  }
  .between-article__img {
    max-width: 200px;
    display: block;
    margin: 0 auto;
  }
}
</style>
