const banner = {
  title: '等保合规',
  btn: '立即咨询',
  summary: '针对等保2.0，Certbase 提供等级保护合规解决方案，助力各行各业企业快速通过等保合规，一站式服务您可享受一站式等保测评，可满足网络攻击防护、数据备份、安全加密、数据审计等等等保合规各项需求，并全面提高客户安全管理还有技术能力水平。'
}

// 安全等级划分
const grade = {
  title: '安全等级划分',
  desc: '不同级别的网络、信息系统、网络上的数据应具备不同的安全保护措施',
  list: [
    {
      title: '第一级',
      content: '信息系统受到破坏后，会对公民、法人和其他组织的合法权益造成损害，但不损害国家安全、社会秩序和公共利益。'
    },
    {
      title: '第二级',
      content: '信息系统受到破坏后，会对公民、法人和其他组织的合法权益产生严重损害，或者对社会秩序和公共利益造成损害，但不损害国家安全。'
    },
    {
      title: '第三级',
      content: '信息系统受到破坏后，会对社会秩序和公共利益造成严重损害，或者对国家安全造成损害。'
    },
    {
      title: '第四级',
      content: '信息系统受到破坏后，会对社会秩序和公共利益造成特别严重损害，或者对国家安全造成严重损害。'
    },
    {
      title: '第五级',
      content: '信息系统受到破坏后，会对国家安全造成特别严重损害。'
    }
  ]
}

// 方案优势
const solution = {
  title: '方案优势',
  options: [
    {
      title: '一站式等保服务',
      content: '与专业的评测机构通力合作，提供全生命周期等保方案，构建完备的安全纵深防御体系。',
      icon: '/images/%E4%BC%98%E5%8A%BF-%E4%B8%80%E7%AB%99%E5%BC%8F%E7%AD%89%E4%BF%9D%E6%9C%8D%E5%8A%A1.25f9e5a.png'
    },
    {
      title: '降低合规成本',
      content: '根据需求灵活选择安全合规产品服务，减少底层信息系统合规建设投入，大大降低客户等保合规投入成本。',
      icon: '/images/%E4%BC%98%E5%8A%BF-%E9%99%8D%E4%BD%8E%E5%90%88%E8%A7%84%E6%88%90%E6%9C%AC.fdcb155.png'
    },
    {
      title: '专业团队护航',
      content: '顶级安全资质的专家团队服务，全流程跟踪，免去多点沟通工作，快速、高效提升企业的合规能力。',
      icon: '/images/%E4%BC%98%E5%8A%BF-%E4%B8%93%E4%B8%9A%E5%9B%A2%E9%98%9F%E6%8A%A4%E8%88%AA.7beb74a.png'
    }
  ]
}

// 等保服务流程
const table = {
  title: '等保服务流程',
  thead: ['角色/流程', '1.定级', '2.备案', '3.建议整改', '4.等保评测', '5.监督检查'],
  tbody: [
    [
      '客户',
      '确定安全保护等级，准备定级报告',
      '准备备案材料，到当地公安机关办理备案手续',
      '基于等保的安全技术和管理要求进行建设和整改，以符合等保要求',
      '准备和接受测评机构的测评',
      '接受公安机关定期的监督和检查'
    ],
    [
      'Certbase',
      '协调运营单位确定定级对象、编写定级报告、组织专家评审',
      '协助运营单位整理备案材料，提交备案申请',
      '根据等保要求进行差距分析，进行整改方案设计，协助落实整改工作',
      '协助运营单位配合测评机构测评，帮助其顺利通过并取得测评报告',
      '协助运营单位进行安全运维'
    ],
    [
      '测评机构',
      '提供等保定级指导',
      '提供等保备案指导',
      '\\',
      '对等级保护对象符合性状况进行测评',
      '\\'
    ],
    [
      '公安机关',
      '\\',
      '审核受理备案材料',
      '\\',
      '\\',
      '监督检查运营单位开展等级保护工作'
    ]
  ]
}

// 方案相关产品
const links = {
  title: '方案相关产品',
  options: [
    {
      label: '等保认证',
      link: '/'
    },
    {
      label: '大批量S/MIME管理方案',
      link: '/'
    },
    {
      label: '公有云',
      link: '/'
    },
    {
      label: '可信认证联盟',
      link: '/'
    },
    {
      label: 'LEI认证',
      link: '/'
    },
    {
      label: '私有PKI',
      link: '/'
    },
    {
      label: '物联网证书',
      link: '/'
    },
    {
      label: '电子合同',
      link: '/'
    }
  ]
}

// 方案架构图及特点
const structure = {
  title: '方案架构图及特点',
  structureFir: {
    title: '传统物理环境架构特点',
    content: '在全面满足等级保护2.0要求及标准框架下，WeCloud.cn等级保护合规方案构建“一个中心，三重防护”的安全架构：“一中心”指的是安全管理中心；“三防护”指的是安全通信网络、计算环境安全和区域边界安全。'
  },
  structureSec: {
    title: '云环境架构特点',
    content: '基于等保合规2.0标准，蔚可云WeCloud.cn等保合规解决方案利用云平台的等保合规性，通过云安全产品和功能满足安全通信网络、安全计算环境、安全管理中心、安全区域边界等方面的要求，为客户搭建全方位的主动防御体系。'
  }
}

// 套餐推荐
// 数据结构过于复杂，直接修改以下中文部分即可
const combo = `
<Jumbotron class="jumbotron-item" title="套餐推荐">
  <table class="compliance-table">
    <thead>
      <tr>
        <th>项目</th>
        <th>等保二级通用套餐</th>
        <th>等保三级基础套餐</th>
        <th>等保三级高级套餐</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>安全通信网络</td>
        <td rowspan="2">
          <div class="flex">
            <i class="el-icon-check"></i>
            web应用防火墙
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            下一代防火墙
          </div>
        </td>
        <td rowspan="2">
          <div class="flex">
            <i class="el-icon-check"></i>
            web应用防火墙
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            下一代防火墙
          </div>
        </td>
        <td rowspan="2">
          <div class="flex">
            <i class="el-icon-check"></i>
            web应用防火墙
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            DDoS云清洗
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            下一代防火墙
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            网络准入、终端管理
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            上网行为管理
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            VPN
          </div>
        </td>
      </tr>
      <tr>
        <td>安全区域边界</td>
      </tr>
      <tr>
        <td>安全计算环境</td>
        <td>
          <div class="flex">
            <i class="el-icon-check"></i>
            主机入侵检测与防病毒
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            堡垒机
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            SSL证书
          </div>
        </td>
        <td>
          <div class="flex">
            <i class="el-icon-check"></i>
            主机入侵检测与防病毒
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            堡垒机
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            数据库审计
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            SSL证书
          </div>
        </td>
        <td>
          <div class="flex">
            <i class="el-icon-check"></i>
            主机入侵检测与防病毒
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            堡垒机
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            数据库审计
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            SSL证书
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            网页防篡改
          </div>
          <div class="flex">
            <i class="el-icon-check"></i>
            数据灾备系统
          </div>
        </td>
      </tr>
      <tr>
        <td>安全管理中心</td>
        <td>\</td>
        <td>综合安全审计系统</td>
        <td>综合安全审计系统</td>
      </tr>
      <tr>
        <td>等保工作过程</td>
        <td>\</td>
        <td>等保资讯服务</td>
        <td>\</td>
      </tr>
    </tbody>
  </table>
</Jumbotron>`

export default {
  compliance: {
    banner,
    solution,
    table,
    links,
    grade,
    combo,
    structure
  }
}