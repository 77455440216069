import application from './application_en'
import common from './common_en'
import crypto from './crypto_en'
import en from './en'
import help from './help_en'
import report from './report_en'
import keyTextarea from './key_textarea_expend_en'
import nofund from './nofund_en'
import seo from './seo_en'
import x509 from './x509_en'
import certhub from './certhub/options_en'
import partner from './partner/index_en'
import brand from './brand_en'
import type from './type_en'
import footer from './footer_en'
import navbar from './navbar_en'
import compliance_auth from './compliance_auth_en'
// compliance 群组
import publicCloud from './compliance/cloud.en'
import zerotrust from './compliance/zerotrust'
import pki from './compliance/pki.en'
import certificationalliance from './compliance/certificationalliance.en'
import gateway from './compliance/gateway.en'
import idc from './compliance/idc.en'
import sase from './compliance/sase.en'

export default {
  ...application,
  ...crypto,
  ...en,
  ...common,
  ...help,
  ...report,
  ...keyTextarea,
  ...nofund,
  ...seo,
  ...x509,
  ...certhub,
  ...partner,
  ...brand,
  ...type,
  ...footer,
  ...navbar,
  ...compliance_auth,
  ...publicCloud,
  ...zerotrust,
  ...pki,
  ...certificationalliance,
  ...idc,
  ...sase,
  ...gateway
}
