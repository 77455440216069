/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-28 13:51:17
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-04-28 13:51:17
 */
export const page = (pageName) => {
  return () => import(`@/pages/${pageName}.vue`)
}
