export default {
  "parseCert": {
    "pageTitle": "X 509 Certificate Decoder",
    "title": "X 509 Certificate Decoder",
    "subtitle": "",
    "btn": "Decoded Certificate",
    "placeholder": "Paste PEM Format Certificate Below",
    "summary": [
      "In this tool you can decode the issued certificate in text form (Base64) and check the information inside and other parameters.",
      "You can download you certificate from you CA"
    ]
  }
}
