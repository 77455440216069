<template>
  <div class="faq"></div>
</template>

<script>

export default {
  name: 'Faq'
}
</script>

<style lang="less" scoped>

</style>
