export default {
  x509: {
    basic: {
      title: 'Basic'
    },
    subject: {
      title: 'Subject'
    },
    issuer: {
      title: 'Issuer'
    },
    from: {
      title: 'Certificate source',
      type: 'Source type',
      from: 'From',
      scan: 'Scan',
      upload: 'Upload'
    },
    ellipsis: {
      expand: 'Expand',
      more: 'More'
    },
    extension: {
      title: 'Extension',
      name: 'Name',
      important: 'Important'
    },
    policy: {
      id: 'Policy ID#',
      adorn: 'Policy modify ID#'
    },
    publicKey: {
      title: 'Public Key Info',
      valueLabel: 'Public Key'
    },
    thumbprints: {
      title: 'Thumbprints'
    },
    serialNumber: 'Serial number',
    version: 'Version',
    validity: 'Validity',
    notBefore: 'Not Before',
    notAfter: 'Not After',
    dnsName: 'DNS Name',
    method: 'method #',
    usage: 'usage#',
    use: 'Use',
    signature: 'Signature algorithm',
    algorithm: 'Algorithm',
    curve: 'Curve',
    value: 'value',
    ca: 'CA',
    y: 'Yes',
    n: 'No'
  }
}
