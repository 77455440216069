<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-04-26 14:17:24
-->
<template>
  <img
    ref="cimg"
    :src="src"
    :alt="alt"
    class="c-img"
    :draggable="false"
    :class="{ 'c-img--block': !src }"
  />
</template>

<script>
import { onMounted } from '@vue/composition-api'
export default {
  name: 'CImg',
  props: {
    src: String,
    alt: String,
    whiteBlock: Boolean
  },
  setup (props, ctx) {
    onMounted(() => {
      // eslint-disable-next-line
      const $cimg = (ctx).refs['cimg']
      // 没有传递src的时候使用占位符
      // eslint-disable-next-line
      if (!$cimg.src) {
        // eslint-disable-next-line
        $cimg.setAttribute('src', require(`@/assets/images/img-${(props).whiteBlock ? 'white' : 'black'}-block.png`))
      }
    })
  }
}
</script>
<style lang="less" scoped>
.c-img {
  display: block;
  width: 100%;
}
.c-img--block {
  width: 32px;
  height: 32px;
}
</style>
