<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-03-26 10:59:17
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-04-18 16:20:36
-->
<template>
  <!-- mobile card table -->
  <div class="mobile-table">
    <ul
      v-for="(group, index) in data"
      :key="`mobile-table-item-${index}`"
      class="mobile-table-item">
      <li class="mobile-table-item-header">{{ group.productName }}</li>
      <!-- <li
        v-for="(cell, index) in group"
        :key="`mobile-table-cell-${index}`"
        class="mobile-table-cell">
        {{ cell }}
      </li> -->
      <!-- <li class="mobile-table-cell">
        <span class="cell-title">我们的价格</span>
        <span class="cell">{{ group.annual }} 元</span>
      </li>
      <li class="mobile-table-cell">
        <span class="cell-title">CA 价格</span>
        <span class="cell">{{ group.originalPrice }} 元</span>
      </li> -->
      <li class="mobile-table-cell">
        <span class="cell-title">验证类型</span>
        <span class="cell">{{ group.trustLevel }}</span>
      </li>
      <li class="mobile-table-cell">
        <span class="cell-title">颁发时间</span>
        <span class="cell">{{ group.issuingTime }}</span>
      </li>
      <li class="mobile-table-cell">
        <span class="cell-title">年限</span>
        <span class="cell">{{ group.validityYears }} 年</span>
      </li>
      <li class="mobile-table-item-footer">
        <span class="cell-title">购买</span>
        <CButton
          v-if="group.subBrand"
          :year="1"
          :brand="group.subBrand.toLowerCase()"
          :validType="group.trustLevel"
          :productType="getProductType(group.sslType)"
          type="primary">
          {{ $t('common').buy }}
        </CButton>
      </li>
    </ul>
  </div>
</template>

<script>
import { getProductType } from '@/utils'
export default {
  name: 'MobileTable',
  props: {
    title: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getProductType
  }
}
</script>

<style lang="less" scoped>
li {
  padding: 20px;
  background-color: fade(@bg-color, 50);
  border-bottom: 1px solid #fff
}
li:last-child {
  border-bottom-color: transparent;
}
.mobile-table-cell,
.mobile-table-item-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-table-item:not(:last-child) {
  margin-bottom: 24px;
}
.mobile-table-item-header {
  background-color: #f3f6fa;
  font-size: 16px;
  font-weight: 500;
  color: #f07a30;
}
</style>
