/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:26:25
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-06-02 10:08:25
 */
import { page } from '../utils'

const toolsRoute = {
  path: '/tools',
  component: page('new/tools/index'),
  redirect: '/tools/gen-csr',
  children: [
    {
      path: 'gen-csr',
      component: page('new/tools/gen-csr'),
      meta: {
        title: 'CSR 文件在线生成工具'
      }
    },
    {
      path: 'parse-csr',
      component: page('new/tools/parse-csr'),
      meta: {
        title: '解析 CSR'
      }
    },
    {
      path: 'parse-cert',
      component: page('new/tools/parse-cert'),
      meta: {
        title: '解析证书'
      }
    },
    {
      path: 'match',
      component: page('new/tools/match'),
      meta: {
        title: '数字证书公私钥匹配'
      }
    },
    {
      path: 'convert-cert',
      component: page('new/tools/convert-cert'),
      meta: {
        title: '证书格式转换'
      }
    },
    {
      path: 'ssl-config',
      component: page('new/tools/ssl-config'),
      meta: {
        title: 'SSL/TLS 证书服务器安装配置生成器'
      }
    }
  ]
}

export default toolsRoute