import { render, staticRenderFns } from "./Signatures.vue?vue&type=template&id=318da893&scoped=true&"
import script from "./Signatures.vue?vue&type=script&lang=js&"
export * from "./Signatures.vue?vue&type=script&lang=js&"
import style0 from "./Signatures.vue?vue&type=style&index=0&id=318da893&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318da893",
  null
  
)

export default component.exports