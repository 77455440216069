const banner = {
  title: '云安全访问服务',
  btn: '立即咨询',
  summary: '云安全访问服务(SASE))是国内首批基于SASE模型的云安全服务平台，将已有的安全能力（上网行为管理、终端安全检测与响应、上网安全防护、内网安全接入服务等）聚合并云化，通过轻量级客户端软件，将网络流量引流上云进行管理和安全检测，满足企业总部、分公司、移动办公多场景下的办公安全需求。'
}
const cjnModules = [
  {
    title: '需求背景',
    span: 8, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_400000110.svg',
        title: '办公场景复杂，管理难',
        content: '企业办公场景涉及总部办公、分公司办公和出差移动办公，多场景难以统一管控。'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '建设成本高，运维复杂',
        content: '企业为满足多个安全需求投入多品类硬件设备，成本高昂，部署运维困难。'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '上网风险面增加',
        content: '入网访问终端多样，上网监管难度升级，员工上班时间使用与工作无关应用、外发机密文件、带宽常拥塞、终端常遭病毒威胁。'
      }
    ]
  },
  {
    title: '产品功能',
    span: 12, // 不用编辑
    layout: 'horizontal', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_100000116.svg',
        title: '上网行为管理',
        content: '可精准审计与管控企业终端上网行为，全面管控文件外发和信息泄露途径，可视化办公效率、保障企业信息安全。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '终端安全检测与响应',
        content: '可对终端侧的安全威胁事前预防、事中防护及事后检测与响应，并联动网络侧的安全防御能力，为企业构建由网到端的全面闭环防护。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '上网安全防护',
        content: '通过对上网流量进行流量趋势分析、威胁分析，实时感知全网安全风险并及时告警，为企业网络规划与优化提供数据支撑。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '内网安全接入服务',
        content: '提供基于零信任的内网应用资源接入，通过身份认证、权限控制等模块，确保企业员工、合作伙伴在全球任何地方都能通过 POP 点更安全、更隐私地访问业务。'
      }
    ]
  },
  {
    title: '产品优势',
    span: 12, // 不用编辑
    layout: 'horizontal', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_100000116.svg',
        title: '多样化场景统一管理',
        content: '企业总部、多分支、移动办公场景均可通过云服务架构统一纳入管理，实现多场景一并管控'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '轻资产',
        content: '企业无需投入多品类硬件，按需订阅安全服务即可，业务扩张可随时弹性扩容，大大降低设备投入成本'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '极简部署运维',
        content: '产品实施简单、运维简化，从管理一堆设备，到管理一个节点，云端专家实时响应，安全事件主动告警、一键处置，大大减轻运维压力'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '云端智能协同',
        content: '海量网端数据可聚合在云端进行统一分析，云端海量情报及安全能力能够与本地终端实时协同，为企业提供终端与云端安全能力全面融合的闭环防护'
      }
    ]
  }
]

const articles = [
  {
    icon: '/logo/globalsign.svg',
    layout: 'start',
    title: '中小企业一体化办公安全',
    content: '无需投入多个硬件设备，按需订阅云服务，即可获取专业、全面的安全防护能力；企业所有终端可实现上网安全防护、上网行为审计管控、信息防泄密管控以及终端威胁防护等能力；支持根据企业人数增加、网络带宽增加进行弹性扩容，适应企业快速发展需求。'
  },
  {
    icon: '/logo/globalsign.svg',
    layout: 'end',
    title: '多分支办公安全',
    content: '集成SD-WAN服务，可与多种SD-WAN设备兼容，实现企业多分支组网统一管理；分支只需部署简易网关，订阅云安全服务，即可对多分支电脑终端进行上网行为审计和管控，实现企业信息防泄密、动态流控管理、员工效率分析等功能；提供无需运维的集中管控平台，可实现多分支统一流量管控、安全事件分析统一展示等；支持根据企业办公地点增加或分公司规模扩大进行弹性扩容，满足企业发展需求。'
  },
 {
    icon: '/logo/globalsign.svg',
    layout: 'start',
    title: '移动办公安全',
    content: '可对脱离公司网络的出差/远程办公的移动笔记本电脑进行统一的策略下发及管控；可对移动办公终端进行统一的终端安全检测、上网行为审计管控以及信息文件防泄密等安全防护；分析移动办公上网数据，并可视化展示办公效率，帮助企业高效管理。'
  },
  {
    icon: '/logo/globalsign.svg',
    layout: 'end',
    title: '企业终端安全防护',
    content: '无需本地安装部署控制端，云端订阅终端安全检测与响应服务即可；同时，用户离网全覆盖，云端病毒库实时更新；云端威胁情报与大数据关联分析技术，能够快速定位失陷主机和威胁风险；人工智能SAVE引擎，能够有效识别并查杀未知、新型威胁等；提供实现预防、防御、检测与响应的立体防护，为终端提供全面、实时、快速、有效的安全防护能力。'
  },
 {
    icon: '/logo/globalsign.svg',
    layout: 'start',
    title: '公共场所网安审计',
    content: '满足公共场所（如连锁门店、咖啡厅、酒店、休闲场所等提供公共WIFI的场所）的网安审计需求，无需部署硬件设备；详细记录并留存入网人员上网轨迹，作为追查依据，满足国家合规需求。'
  }
]

export default {
  sase: {
    banner,
    articles,
    cjnModules
  }
}