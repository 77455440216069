/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:02
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-06-02 09:50:20
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueMeta from 'vue-meta'
import VueCompositionApi from '@vue/composition-api'
import packageJson from '../package.json'
import VueTippy, { TippyComponent } from "vue-tippy";
import './filters'
import '@/components/global'
import "tippy.js/themes/light.css";
import 'element-ui/lib/theme-chalk/index.css'
import 'remixicon/fonts/remixicon.css'
import './style/index.less'

console.log('version', `${process.env.NODE_ENV}_${packageJson.version}`)

Vue.use(VueMeta)
Vue.use(VueCompositionApi)
if (window.hljs) {
  Vue.use(window.hljs.vuePlugin)
}

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

Vue.config.productionTip = false
Vue.prototype.VUE_APP_TARGET = process.env.VUE_APP_TARGET
Vue.prototype.toApp = () => {
  window.location.href = process.env.VUE_APP_TARGET
}

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
})
  .$mount('#app')
