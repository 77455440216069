<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-04-26 09:59:01
-->
<template>
  <div class="browser-preview container">
    <el-row :gutter="src ? 48 : 0">
      <el-col
        :span="src ? 12 : 24"
        :md="src ? 12 : 24"
        :sm="src ? 12 : 24"
        :xs="24"
      >
        <CSection class="summary" :title="title">
          <slot>
            <p>All the TLS certificates we offer are 100% trusted and website visitors are not bothered by browser errors.</p>
            <p>In a web browser, secure communication is shown in the address bar using the lock icon and the letter "s" next to the http in the URL.</p>
            <p>You can check the certificate owner and issuer by clicking on the lock. Learn moe about the TLS certificate in the browser.</p>
          </slot>
        </CSection>
      </el-col>
      <el-col
        v-if="src"
        :span="12"
        :md="12"
        :sm="12"
        :xs="24"
      >
        <CImg :src="src" alt="" :draggable="false" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'BrowserPreview',
  props: {
    title: String,
    src: String
  }
}
</script>

<style lang="less" scoped>
.browser-preview {
  padding: 48px 0;
}
.summary {
  padding-top: 100px;
}
@media screen and (max-width: 1000px){
  .summary {
    padding-top: 12px;
  }
}
@media screen and (max-width: 768px){
  .summary {
    padding-top: 0;
    padding-bottom: 48px;
  }
}
</style>
