<template>
  <div class="code-products" :class="`code-products-${$i18n.locale}`">
    <Jumbotron :title="$t('product').pricePreview.title.replace('$productName', product.productName)">
      <p class="text-center text-grey">{{ product.priceListDescription }}</p>
      <el-row :gutter="24">
        <el-col
          :span="8"
          :md="8"
          :sm="12"
          :xs="24"
        >
          <!-- clear-bottom -->
          <Card>
            <div class="flex first-card">
              <div class="code-product-card-top">
                <h4 class="title">1{{ $t('product').pricePreview.yearPrice }}</h4>
                <div class="subtitle">{{ $t('common').currencySymbol }}{{ product.annual }}</div>
                <div class="flex">
                  <ul>
                    <li>
                      <i class="ri-checkbox-circle-line"></i>
                      <span>{{ $t('product').pricePreview.caPrice }}: {{ $t('common').currencySymbol }}{{ product.originalPrice }}</span>
                    </li>
                    <li>
                      <i class="ri-checkbox-circle-line"></i>
                      <span>{{ $t('product').pricePreview.codePriceSummary1.replace('$brand', product.subBrand) }}</span>
                    </li>
                  </ul>
                </div>
                <div class="controller-container flex flex-center">
                  <CButton
                    v-if="product.subBrand"
                    :year="1"
                    :brand="product.subBrand.toLowerCase()"
                    :validType="product.trustLevel"
                    :productType="getProductType(product.sslType)"
                    type="primary" />
                </div>
              </div>
            </div>
          </Card>
        </el-col>
        <el-col
          :span="8"
          :md="8"
          :sm="12"
          :xs="24"
        >
          <!-- clear-bottom -->
          <Card>
            <h4 class="title">2{{ $t('product').pricePreview.yearPrice }}</h4>
            <div class="subtitle">{{ $t('common').currencySymbol }}{{ product.biannual }}</div>
            <ul>
              <li>
                <i class="ri-checkbox-circle-line"></i>
                <span>{{ $t('product').pricePreview.caPrice }}: {{ $t('common').currencySymbol }}{{ Number(product.originalPrice) * 2 }}</span>
              </li>
              <li>
                <i class="ri-checkbox-circle-line"></i>
                <span>{{ $t('product').pricePreview.codePriceSummary1.replace('$brand', product.subBrand) }}</span>
              </li>
              <li>
                <i class="ri-checkbox-circle-line"></i>
                <span>{{ $t('product').pricePreview.codePriceSummary2 }}</span>
              </li>
            </ul>
            <div class="controller-container flex flex-center">
              <CButton
                v-if="product.subBrand"
                :year="2"
                :brand="product.subBrand.toLowerCase()"
                :validType="product.trustLevel"
                :productType="getProductType(product.sslType)"
                type="primary" />
            </div>
          </Card>
        </el-col>
        <el-col
          :span="8"
          :md="8"
          :sm="12"
          :xs="24"
        >
          <!-- clear-bottom -->
          <Card>
            <h4 class="title">3{{ $t('product').pricePreview.yearPrice }}</h4>
            <div class="subtitle">{{ $t('common').currencySymbol }}{{ product.triannual }}</div>
            <ul>
              <li>
                <i class="ri-checkbox-circle-line"></i>
                <span>{{ $t('product').pricePreview.caPrice }}: {{ $t('common').currencySymbol }}{{ Number(product.originalPrice) * 3 }}</span>
              </li>
              <li>
                <i class="ri-checkbox-circle-line"></i>
                <span>{{ $t('product').pricePreview.codePriceSummary1.replace('$brand', product.subBrand) }}</span>
              </li>
              <li>
                <i class="ri-checkbox-circle-line"></i>
                <span>{{ $t('product').pricePreview.codePriceSummary3 }}</span>
              </li>
            </ul>
            <div class="controller-container flex flex-center">
              <CButton
                v-if="product.subBrand"
                :year="3"
                :brand="product.subBrand.toLowerCase()"
                :validType="product.trustLevel"
                :productType="getProductType(product.sslType)"
                type="primary" />
            </div>
          </Card>
        </el-col>
      </el-row>
    </Jumbotron>
  </div>
</template>

<script>
import { getProductType } from '@/utils'
export default {
  name: 'CodeProducts',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    getProductType
  }
}
</script>

<style lang="less" scoped>

.code-products {
  background-color: @bg-color;
  margin: 0 -24px;
  padding: 0 5%;
  .code-product-card-top {
    min-height: 272px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  & /deep/ .card {
    position: relative;
    margin: 0 auto 24px;

    &.clear-bottom {
      margin-bottom: -24px;
    }
    .title {
      color: @theme-color;
    }
    .subtitle {
      padding: 12px 0;
      font-size: 36px;
      text-align: center;
      border-top: @border;
      border-bottom: @border;
      margin: 12px 0;
    }
  }
  & /deep/ .jumbotron .container {
    max-width: unset;
  }
  .first-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 272px;
  }
  ul {
    padding: 12px 0;
  }
  li {
    display: flex;
    margin-bottom: 12px;
    [class*="ri-"] {
      margin-right: 6px;
      font-size: 18px;
      color: @success-color;
    }
  }
}
.code-products-zh {
  .first-card {
    min-height: calc(346px - 48px);
  }
}
@media screen and (max-width: 768px){
  .code-products {
    padding: 0;
  }
}
</style>
