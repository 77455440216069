<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-05-10 14:28:00
-->
<template>
  <!-- max jumbotron -->
  <div
    class="jumbotron"
    :class="{ 
      'jumbotron-layout--left': layout, 
      'google-style': googleStyle,
      'is-gray': isGray
    }"
  >
    <h4 v-if="topTitle" class="jumbotron-toptitle">{{ topTitle }}</h4>
    <h1 v-if="title" class="jumbotron-title">{{ title }}</h1>
    <slot name="subtitle">
      <h4 v-if="subtitle" class="jumbotron-subtitle">{{ subtitle }}</h4>
    </slot>
    <div :class="{ container }">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Jumbotron',
  props: {
    title: String,
    subtitle: String,
    topTitle: String,
    layout: String,
    isGray: Boolean,
    googleStyle: Boolean,
    container: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="less" scoped>
.jumbotron {
  padding: 48px 0;
  &.jumbotron-layout--left {
    .jumbotron-title,
    .jumbotron-subtitle {
      text-align: left;
    }
    .jumbotron-subtitle {
      margin: 6px 0;
      max-width: unset;
    }
  }
  &.google-style {
    .jumbotron-title {
      font-weight: 400;
    }
  }
  &.is-gray {
    background-color: #f5f5f5;
  }
}
.jumbotron-title,
.jumbotron-subtitle,
.jumbotron-toptitle {
  text-align: center;
}
.jumbotron-subtitle,
.jumbotron-toptitle {
  color: @text-color-l;
  font-weight: 400;
  // letter-spacing: 1.5px;
  max-width: 450px;
  margin: 6px auto 0;
}
.jumbotron-toptitle {
  margin-top: 0;
  margin-bottom: 6px;
}
.container {
  margin-top: 24px;
}
</style>
