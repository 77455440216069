export default {
  "parseCsr": {
    "pageTitle": "CSR decoder",
    "title": "CSR decoder",
    "subtitle": "",
    "placeholder": "Paste PEM format CSR Below",
    "summary": [
      "In this tool you can decode CSR in text form (Base64) and check the information inside and other parameters.",
      "You can obtain CSR from your server administrator, or you can generate it together with your private key in our administration。"
    ]
  }
}
