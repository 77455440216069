<template>
  <div class="card-group">
    <slot></slot>
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'

export default {
  name: 'CardGroup',
  mounted() {
    this.formatCard()
  },
  methods: {
    formatCard
  }
}

function formatCard() {
  // const $cards = document.querySelectorAll('.card-group .sync-card')
  const $cards = this.$el.querySelectorAll('.sync-card')
  const cardsHeightContainer = []
  $cards.forEach(card => {
    cardsHeightContainer.push(card.clientHeight)
  })
  $cards.forEach(card => {
    const cardHeight = card.clientHeight
    const maxCardHeight = Math.max(...cardsHeightContainer)
    // if (cardHeight !== maxCardHeight) {
    //   console.log('card', card)
    //   card.setAttribute('style', `min-height:${maxCardHeight}px`)
    // }
    card.setAttribute('style', `min-height:${maxCardHeight}px`)
  })
}
</script>
