const banner = {
  title: 'PKI解决方案',
  btn: '立即咨询',
  summary: '使用全球领先的 PKI 供应商打造本地和托管 PKI 解决方案，让您的业务更加可靠。'
}
const cjnModules = [
  {
    title: '功能介绍',
    span: 8, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_400000110.svg',
        title: '平衡安全性和用户体验',
        content: '我们的公共密钥基础建设 (PKI) 解决方案为您在数字生态系统中实现可用性和安全性之间的完美平衡提供了条件。您可以为员工、客户和合作伙伴提供其所需的信息和无缝访问，同时还能确保企业安全性。'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '可信身份助力业务转型',
        content: '公开密钥基础建设 (PKI) 和数字证书对于保护高价值交易、验证身份和在线传输敏感信息非常必要。我们的 PKI 解决方案所提供的密钥和证书管理服务可实现不同应用程序间的加密与数字签名功能，帮助建立并维护一个值得信赖的网络环境。'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '灵活的运营模式',
        content: 'Certbase 业内领先的公开密钥基础建设解决方案既可用于托管公钥基础设施，也可用于本地模式。丰富的选择让您能够挑选出最适合您的成本效益、安全性和控制要求的 PKI 解决方案。'
      }
    ]
  },
  {
    title: 'PKI 特点',
    span: 12, // 不用编辑
    layout: 'horizontal', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_100000116.svg',
        title: '久经考验的 PKI 行业领先地位',
        content: '自 1994 年世界上首款可商用的公钥基础设施发布以来，我们的业务已经遍布全球 150 多个国家/地区，深得最具安全意识的政府、银行和企业的信赖。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '面向未来与灵活性',
        content: '管理基于数字证书的身份的完整生命周期，在更广泛的用例上实现加密、数字签名和证书身份验证功能。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '发挥更高价值',
        content: '为您的运营注入实时客户洞见，加快产品和服务开发，并将您的流程与客户和合作伙伴相集成。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '基础性和可扩展性',
        content: '发现并部署基础技术，提高企业灵活性，将大量原始数据转化为具有可操作的业务情报。'
      }
    ]
  },
  {
    title: '产品优势',
    span: 6, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_400000110.svg',
        title: '私有CA托管能力',
        content: '用户无需构建和维护复杂的CA基础设施，在华为云上按需付费即可轻松获得CA管理和证书管理服务能力'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '完整CA层次结构',
        content: '支持创建灵活的 CA 层次结构，包括根 CA 和从属 CA，同时支持外部CA，满足更多应用部署'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '生命周期管理',
        content: '提供证书、密钥统一管理，具备百万级以上的证书服务管理能力，支持证书撤销列表及时提醒租户证书状态，避免证书过期'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '多种密钥算法',
        content: '支持RSA2048、RSA4096、EC256、EC384等多种密钥算法，支持证书格式x.509v3，符合PKI/CA国际标准'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: 'API灵活集成',
        content: '提供丰富的API接口，可以帮助用户在开发环境高效集成，快速进行产品部署，为企业租户提供了巨大的灵活性'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '密钥存储安全可靠',
        content: '支持通过汇智云安密钥管理服务（KMS）和硬件安全模块 (HSM) 提供安全保护，可以安全可靠保存密钥'
      }
    ]
  },
  {
    title: '产品功能',
    span: 6, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_400000110.svg',
        title: 'CA管理',
        content: '提供根CA和从属CA层级化管理，提供创建、查询、启用、禁用、延迟删除、恢复等CA管理能力'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '证书管理',
        content: '提供对私有证书的申请、颁发、查询、删除、吊销等，具备百万级以上的证书服务管理能力'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '密钥算法',
        content: '支持多种密钥算法，其中包括：RSA2048、RSA4096、EC256、EC384等。支持x.509 v3的证书格式'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '证书撤销列表（CRL）管理',
        content: '支持自动向用户OBS桶发布和更新CRL，供用户或应用下载，应用程序、服务以及设备可以通过CRL评估证书状态'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '关键操作审计',
        content: '支持对创建CA、禁用CA、启用CA、申请证书、删除证书、吊销证书等关键操作的审计与查询'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: 'API接口',
        content: '支提供创建CA、禁用CA、启用CA、删除CA、查询CA、申请证书、获取证书、吊销证书、删除证书等API接口'
      }
    ]
  }
]

const articles = [
  {
    icon: '/images/content/ia_500000118.svg',
    layout: 'start',
    title: '车联网应用',
    content: '车企TSP使用私有证书管理服务，为每台辆终端颁发证书，提供车-车、车-云、车-路交互时鉴权、认证、加密等安全能力。'
  },
  {
    icon: '/images/content/ia_500000118.svg',
    layout: 'end',
    title: '物联网应用',
    content: 'IoT平台使用私有证书管理服务，为每台IoT设备颁发证书，并通过IoT平台联动PCA，实现IoT设备的身份校验与认证，保障IoT场景下设备接入安全'
  },
  {
    icon: '/images/content/ia_500000118.svg',
    layout: 'start',
    title: '企业信息化应用',
    content: '建立企业统一证书管理体系，实现证书全生命周期管理，融入持续监控和自动化，防范因证书管理不善导致的风险。'
  }
]

export default {
  privatePki: {
    banner,
    articles,
    cjnModules
  }
}