/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-05-05 09:54:50
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-06-02 09:57:39
 */
import { page } from '../utils'

const partnerRoute = {
  path: '/partner',
  component: page('partner/index'),
  redirect: '/partner/enterprise',
  children: [
    {
      path: 'affiliate',
      component: page('partner/new/affiliate'),
      meta: {
        title: '联盟会员计划'
      }
    },
    {
      path: 'enterprise',
      component: page('partner/new/enterprise'),
      meta: {
        title: '大客户合作'
      }
    },
    {
      path: 'reseller',
      component: page('partner/new/reseller'),
      meta: {
        title: '代理销售'
      }
    },
    {
      path: 'member',
      component: page('partner/new/member'),
      meta: {
        title: '会员折扣计划'
      }
    }
  ]
}

export default partnerRoute
