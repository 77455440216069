const banner = {
  title: '等保测评服务',
  btn: '立即咨询',
  summary: '等级保护测评是验证信息系统是否满足相应安全保护等级的评估过程，信息安全等级要求不同安全等级的信息系统应具有不同的安全保护能力，对未涉及国家秘密的信息系统安全等级保护状况进行检测评估，并出具相关的等级测评报告。'
}
const cjnModules = [
  {
    title: '为什么要做等保？',
    span: 8, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_100000116.svg',
        title: '国家法律要求',
        content: '国家法律法规及行业监管政策都要求开展等级保护工作。如《网络安全法》和《信息安全等级保护管理办法》明确规定信息系统运营、使用单位应当按照网络安全等级保护制度要求，履行安全保护义务，如果拒不履行，将会受到相应处罚。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '客户要求',
        content: '信息系统运营单位在向外部客户提供业务服务时，通过等保测评，能向客户及利益相关方展示信息系统安全性承诺，增强客户、合作伙伴及利益相关方的信心。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '自身安全要求',
        content: '信息系统运营、使用单位通过开展等级保护工作可以发现系统内部的安全隐患与不足之处，可通过安全整改提升系统的安全防护能力，降低被攻击的风险。'
      }
    ]
  },
  {
    title: '产品描述',
    span: 12, // 不用编辑
    layout: 'horizontal', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_400000110.svg',
        title: '一站式服务，省心省力',
        content: '从选择符合公安部要求的专业测评机构，到选择符合等级保护要求的安全整改方案，并最终获取测评报告，企业可以在平台上轻松完成。'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '提供专业的现场服务',
        content: '企业购买测评服务后，评测机构将提供专业的现场服务，包括对系统情况现场调研、编写符合企业现状的测试方案和测试计划、提供整改方案以及针对评测中发现的问题进行沟通确认，最终签发评测报告。'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '采用等级保护解决方案，更快完成安全整改',
        content: '企业可以选择汇智云安提供的等级保护解决方案，快速完成安全整改，满足等保的技术合规要求。安全技术体系建立的主要手段包括使用安全产品、加固系统配置和开发安全控制。其中通过使用成熟的安全产品，可以快速满足合规要求。'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '相关产品和服务',
        content: '高防服务 DDOS、WEB应用防火墙 WAF、SSL证书管理 SLL、堡垒机 HAS、主机入侵检测 HIDS、数据库审计 DBAudit、态势感知 SA'
      }
    ]
  },
  {
    title: '信息安全等级保护测评的流程',
    span: 12, // 不用编辑
    layout: 'horizontal', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_400000110.svg',
        title: '测评准备',
        content: '开展等级测评工作的前提和基础，是整个等级测评过程有效性的保证。测评准备工作是否充分直接关系到后续工作能否顺利开展。本活动的主要任务是掌握被测系统的详细情况，准备测试工具，为编制测评方案做好准备。'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '方案编制',
        content: '为现场测评提供最基本的文档和指导方案。主要任务是确定与被测信息系统相适应的测评对象、测评指标及测评内容等，并根据需要重用或开发测评指导书测评指导书，形成测评方案。'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '现场测评',
        content: '开展等级测评工作的核心活动。主要任务是按照测评方案的总体要求，严格执行测评指导书测评指导书，分步实施所有测评项目，包括单元测评和整体测评两个方面，以了解系统的真实保护情况，获取足够证据，发现系统存在的安全问题。'
      },
      {
        icon: '/images/content/ia_400000110.svg',
        title: '分析与报告编制',
        content: '给出等级测评工作结果，总结被测系统整体安全保护能力的综合评价活动。主要任务是根据现场测评结果和GB/T28448—2012的有关要求，通过单项测评结果判定、单元测评结果判定、整体测评和风险分析等方法，找出整个系统的安全保护现状与相应等级的保护要求之间的差距，并分析这些差距导致被测系统面临的风险，从而给出等级测评结论，形成测评报告文本。'
      }
    ]
  },
  {
    title: '适用行业',
    span: 8, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_100000116.svg',
        title: '医疗行业',
        content: '国家卫健委《互联网医院管理办法(试行)》规定了承载互联网医院的平台必须通过等保三级测评，等保2.0时代，汇智云安助力打造全栈安全、合规的数字化医院平台，化被动防护为主动防御，更好保护医院中各类应用数据的信息安全'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '网络货运行业',
        content: '根据《网络平台道路货物运输经营管理实施细则（暂行）》的相关规定，取得三级及以上信息系统安全等级保护备案证明，方能申领《道路运输经营许可证》，汇智云安帮助网络货运经营者过等保，满足行业准入条件'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '教育行业',
        content: '教育部要求高等院校的教育移动应用按照《网络安全法》和网络安全等级保护2.0的要求完成定级备案和测评整改。在线教育安全等保汇智云安在行动，为教育APP提供专业等保合规安全解决方案'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '金融行业',
        content: '中国人民银行发布金融行业标准《金融行业网络安全等级保护实施指引》，汇智云安从网络、数据及管理技术等多维度联动防护，为金融客户构建稳定、可靠、合规的安全解决方案，全面提升金融行业系统网络安全整体防护水平'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '政企公共事业',
        content: '《中华人民共和国网络安全法》第三十一条规定国家对公共通信和信息服务、交通、公共服务、电子政务等重要行业和领域，实行重点保护，汇智云安推进打造智能、安全的交通安全互联网平台，使人民群众方便快捷地在网上办理业务'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '电商行业',
        content: '汇智云安助力电商行业客户精准防御撞库爆破、薅羊毛、黄牛倒卖、网页篡改、DDoS&CC攻击、数据窃取泄露、木马等一系列风险，在信息安全建设方面上升到一个新台阶'
      }
    ]
  },
{
    title: '等保测评常见问题',
    span: 12, // 不用编辑
    layout: 'horizontal', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_100000116.svg',
        title: '信息系统的测评多久需要测一次？',
        content: '四级信息系统要求每半年至少开展一次测评；三级信息系统要求每年至少开展一次测评；二级信息系统一般每两年开展一次测评，时间上没有强制要求，部分行业有行业标准要求，如电力行业明确二级系统两年做一次测评。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '网络安全等级保护之备案',
        content: '网络安全等级保护备案工作包括信息系统备案、受理、审核和备案信息管理等工作。在备案时需提交备案所需资料，并遵从备案工作流程。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '网络安全等级保护之物理安全建设',
        content: '等级保护基本技术要求涉及物理安全、网络安全、主机安全、应用和数据安全等几个重要的方面，本文以机房建设为例来探讨一下等级保护中物理安全建设问题。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '新形势下网络安全法与等级保护二者的关系',
        content: '国家实行网络安全等级保护制度。网络运营者应当按照网络安全等级保护制度的要求，履行安全保护义务，保障网络免受干扰、破坏或者未经授权的访问，防止网络数据泄露或者被窃取、篡改。'
      }
    ]
  }
]


export default {
  complianceAuth: {
    banner,
    cjnModules
  }
}