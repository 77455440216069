// 证书品牌
const brand = {
  title: '证书品牌'
}

// 验证类型
const validation = {
  title: '验证类型',
  options: [
    {
      label: '域名验证 TLS 证书',
      link: '/ssl/domain-validation'
    },
    {
      label: '企业验证 TLS 证书',
      link: '/ssl/organization-validation'
    },
    {
      label: '扩展验证 TLS 证书',
      link: '/ssl/extended-validation'
    },
  ]
}

// 证书类型
const type = {
  title: '证书类型',
  options: [
    {
      label: '单域名 TLS 证书',
      link: '/ssl/single-domain'
    },
    {
      label: '多域名 TLS 证书',
      link: '/ssl/multi-domain'
    },
    {
      label: '通配符 TLS 证书',
      link: '/ssl/wildcard'
    },
    {
      label: '公网 IP 地址 TLS 证书',
      link: '/ssl/public-ip-address'
    },
    {
      label: '弹性 TLS 证书',
      link: '/ssl/flex-tls'
    },
    {
      label: '中国 OCSP TLS 证书',
      link: '/ssl/china-optimized-ocsp-tls'
    }
  ]
}

// 数字签名
const sign = [
  {
    icon: '/images/navbar/icon-code-signing.svg',
    title: '微软代码签名',
    link: '/digital-signing/code-signing',
    desc: '用于对代码/程序签名'
  },
  {
    icon: '/images/navbar/icon-ev-code-signing.svg',
    title: '微软 EV 代码签名',
    link: '/digital-signing/ev-code-signing',
    desc: '立即获得Smart Screen 信誉，支持驱动程序'
  },
  {
    icon: '/images/navbar/icon-email-signing.svg',
    title: '邮件签名证书',
    link: '/digital-signing/email-signing',
    desc: '对电子邮件通信进行加密和数字签名'
  },
  {
    icon: '/images/navbar/icon-document-signing.svg',
    title: '文件签名证书',
    link: '/digital-signing/document-signing',
    desc: '电子合同常用的签名解决方案'
  }
]

// 工具
const tools = [
  {
    title: '证书工具',
    desc: '免费的 TLS 证书工具',
    options: [
      [
        {
          label: '创建 CSR',
          link: '/tools/gen-csr'
        },
        {
          label: '解析 CSR',
          link: '/tools/parse-csr'
        },
        {
          label: '解析证书',
          link: '/tools/parse-cert'
        }
      ],
      [
        {
          label: '公私钥匹配',
          link: '/tools/match'
        },
        {
          label: '证书格式转换',
          link: '/tools/convert-cert'
        },
        {
          label: 'TLS 配置生成器',
          link: '/tools/ssl-config'
        }
      ],
    ]
  },
  {
    title: '证书管理',
    desc: '便捷的证书管理平台',
    options: [
      [
        {
          label: '发现证书',
          link: '/certhub/discovery'
        },
        {
          label: '证书库存管理',
          link: '/certhub/inventory'
        },
        {
          label: '证书分析',
          link: '/certhub/analyse'
        }
      ],
      [
        {
          label: '证书审计',
          link: '/certhub/audit'
        },
        {
          label: '证书监控',
          link: '/certhub/monitor'
        },
        {
          label: '证书日志查询',
          link: '/certhub/log'
        }
      ],
    ]
  }
]

// 解决方案
const compliance = [
  {
    title: '解决方案',
    options: [
      {
        label: '等保整改',
        link: '/compliance'
      },
      {
        label: '等保评测',
        link: '/evaluation'
      },
      {
        label: '公有云',
        link: '/public-cloud'
      },
    ]
  },
  {
    options: [
      // {
      //   label: '飞连',
      //   link: '/zerotrust'
      // },
      {
        label: '私有 PKI',
        link: '/private-pki'
      },
      {
        label: '可信电子签名',
        link: '/signature'
      },
    ]
  },
  {
    options: [
      {
        label: '可信认证联盟',
        link: '/certificationalliance'
      },
      {
        label: '客户端证书',
        link: '/scalesmime'
      },
      {
        label: '云安全访问服务',
        link: '/sase'
      }
    ]
  },
  {
    options: [
      {
        label: 'SSL应用安全网关',
        link: '/gateway'
      },
      {
        label: 'IDC 服务',
        link: '/idc'
      }
    ]
  },
]

// 合作伙伴
const partner = [
  {
    icon: '/images/navbar/enterprise.svg',
    title: '大客户计划',
    desc: '高效管理所有网站的证书',
    link: '/partner/enterprise'
  },
  {
    icon: '/images/navbar/reseller.svg',
    title: '经销商/代理商计划',
    desc: '用我们的产品提升您的业绩',
    link: '/partner/reseller'
  },
  {
    icon: '/images/navbar/affiliate.svg',
    title: '联盟推广计划',
    desc: '在网站上推广通过销售赚取佣金',
    link: '/partner/affiliate'
  },
  {
    icon: '/images/navbar/member.svg',
    title: '会员折扣计划',
    desc: '每次购买都能享受折扣优惠',
    link: '/partner/member'
  },
]

// 联系我们
const contact = {
  tel: '电话',
  contactNow: '立即聊天',
  contactUs: '联系我们',
  serviceTime: '中国 服务时间：9:00～18:00 周一至周五',
  serviceTel: ['400-128-0988', '177-0811-9188'],
  aboutUs: '关于我们',
  slogan: '中立 TLS//PKI 提供商',
  callback: '联系地址',
  notWait: '成都市天府新区华府大道1号蓝润置地T3',
  chatNow: '在线客服',
  chatToTeam: '与我们的销售和支持团队交流，快速解决产品功能、价格等方面的问题。'
}

export default {
  nnavbar: {
    sign,
    type,
    tools,
    brand,
    contact,
    partner,
    compliance,
    validation
  }
}