const banner = {
  title: '数字签名解决方案',
  btn: '立即咨询',
  summary: '数字签名解决方案基于可信第三方CA颁发的数字证书，使用移动终端为个人及企业用户提供身份认证、电子签名、电子签章、文档签署等服务。适用于移动互联网环境下的流程流转、文档签署、合同签署等数字签名场景，为互联网应用及签名人提供便捷、可靠的电子签名服务，满足业务移动化、在线化场景下的电子签名需求。'
}
const cjnModules = [
  {
    title: '服务内容',
    span: 12, // 不用编辑
    layout: 'horizontal', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: '身份信息认证',
        content: '多种身份认证手段在线鉴别核实个人、企业身份确保电子签署者真实的签署身份，不可仿冒与否认'
      },
      {
        icon: '/images/content/key.svg',
        title: 'CA中心认证',
        content: '权威专业的第三方CA认证机构提供电子认证服务，提供合法可靠的电子认证、电子签名/签章、时间戳等服务。'
      },
      {
        icon: '/images/content/key.svg',
        title: '电子签章',
        content: '为用户提供专业、可信、便捷、高性的电子签章服务，解决企业运营中签署可靠电子文件需求，保障电子文件真实、完整、不可抵赖性'
      },
      {
        icon: '/images/content/key.svg',
        title: '电子签署',
        content: '严格遵循《电子签名法》，符合国家密码管理要求、兼容国际标准，支持USBkey/手写/移动/短信/指纹/人脸/扫码/批量签署，满足多场景、各行业的电子签署需求。'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据存证',
        content: '采用文件加密分散存储确保信息和数据安全，存证报告详细记载业务全流程信息，形成可追溯的完整证据链，对接第三方公证机关、司法鉴定机构，确保签署结果可信。'
      },
      {
        icon: '/images/content/key.svg',
        title: '司法举证',
        content: '随时随地多种手段线上线下验证签名有效性真实性，可信完整的电子证据链条，为电子签署提供可靠性保障，CA验证报告、公证书、司法鉴定报告，已获法院判例认可。'
      }
    ]
  },
  {
    title: '服务特点',
    span: 6, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: '移动便捷',
        content: '使用手机等移动终端设备完成签名操作。'
      },
      {
        icon: '/images/content/key.svg',
        title: '节约成本',
        content: '无需购买额外硬件设备，降低使用成本，易于推广。'
      },
      {
        icon: '/images/content/key.svg',
        title: '提升效率',
        content: '使用电子签名代替传统纸质签名，节约办公资源、提升业务流转效率。'
      },
      {
        icon: '/images/content/key.svg',
        title: '安全可靠',
        content: '采用门限分割的密钥管理技术，证书密钥由客户端密钥因子和云端密钥因子共同组成，两端协同完成电子签名运算，实现用户对签名密钥的专有控制。'
      }
    ]
  },
  {
    title: '应用场景',
    span: 12, // 不用编辑
    layout: 'full-bottom', // 不用编辑
    contents: [
      {
        icon: '/images/scene_1_1608452855.png',
        title: '安全',
        content: '具备OTP两步验证和设备实时动态认证等措施，保证员工不会因为密码的遗失泄漏导致账号被盗用，任何新设备登录都需要通过飞连的安全基线检测'
      },
      {
        icon: '/images/scene_1_1608452855.png',
        title: '效率',
        content: '统一账号体系，通过SSO认证登录的方式打通企业内部各类系统平台，严格把控内部应用权限的可靠性，对于内部员工入、离职以及调岗等场景做了充分的权限管控措施'
      }
    ]
  },
]

const articles = [
  {
    icon: '/logo/globalsign.svg',
    layout: 'start',
    title: '数据访问',
    content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
  },
  {
    icon: '/logo/globalsign.svg',
    layout: 'end',
    title: '数据访问',
    content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
  }
]

export default {
  signature: {
    banner,
    articles,
    cjnModules
  }
}