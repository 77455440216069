export default {
  title: '证书管理联动',
  options: {
    discovery: {
      summary: '发现证书',
      link: '/certhub/discovery',
      linkText: '前往发现证书'
    },
    inventory: {
      summary: '查询证书日志',
      link: '/certhub/inventory',
      linkText: '前往查询证书日志'
    },
    analyse: {
      summary: '证书分析',
      link: '/certhub/analyse',
      linkText: '前往证书分析'
    },
    audit: {
      summary: '证书审计',
      link: '/certhub/audit',
      linkText: '前往证书审计'
    },
    monitor: {
      summary: '证书监控',
      link: '/certhub/monitor',
      linkText: '前往证书监控'
    },
    log: {
      summary: '证书日志',
      link: '/certhub/log',
      linkText: '前往证书日志'
    }
  }
}
