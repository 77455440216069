/**
 * 证书审计 英文版
 * 页面路由 https://certbase.com/en/certhub/audit
 */

 // Banner
const banner = {
  title: 'Title',
  summary: 'summary',
  link: '',
  linkText: '立即体验',
  bgUrl: '/images/certhub/certhub_audit.svg'
}

// 证书审计说明
const manage = {
  title: '证书审计',
  options: [
    {
      title: '',
      summary: ''
    },
    {
      title: '',
      summary: ''
    },
    {
      title: '',
      summary: ''
    }
  ]
}

// 特性
const features = {
  title: '特性',
  options: [
    [
      // 若是图片填充填写图片URL 若不是保留空值
      { title: '', content: [], img: '' }, // 左
      { title: '', content: [], img: '' } // 右
    ],
    [
      { title: '', content: [], img: '' },
      { title: '', content: [], img: '' }
    ],
    [
      { title: '', content: [], img: '' },
      { title: '', content: [], img: '' }
    ]
  ]
}

// 步骤
const step = {
  title: '步骤',
  link: '', // 按钮点击前往地址
  linkText: '查看步骤详情',
  options: [
    {
      img: '',
      summary: ''
    },
    {
      img: '',
      summary: ''
    },
    {
      img: '',
      summary: ''
    }
  ]
}

export default {
  banner,
  manage,
  features,
  step
}
