/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 10:13:23
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-04-26 10:51:23
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from '@/config/lang/zh'
import en from '@/config/lang/en'

Vue.use(VueI18n)

const messages = {
  zh,
  en
}

const i18n = new VueI18n({
  locale: 'zh', // 设置地区
  messages, // 设置地区信息
  fallbackLocale: 'zh'
})

export default i18n
