<template>
  <!-- Product details -->
  <ContainerCard>
    <div class="product">
      <div class="product-summary">
        <h1 class="summary-title flex flex-between">
          <span>{{ formatProductName(product) }}</span>
          <CButton
            v-show="product.subBrand"
            :brand="product.subBrand.toLowerCase()"
            :validType="product.trustLevel"
            :productType="getProductType(product.sslType)"
            type="primary" />
        </h1>
        <div class="summary-content">
          {{ product.description }}
        </div>
      </div>
      <div class="product-summary-card flex">
        <Card is-black v-if="product.trustLevel">
          <Grade :grade="{
            dv: 1,
            ov: 2,
            ev: 3
          }[product.trustLevel]"/>
          <span>{{ $t('common')[product.trustLevel] }}</span>
        </Card>
        <Card v-if="product" class="signature-container" is-black>
          <Signatures :product="product" />
        </Card>
        <Card is-black v-if="product.subBrand">
          <p v-if="product.additionalDomains " class="product-summary-card-content">{{ product.additionalDomains }}</p>
          <i v-else class="ri-close-fill"></i>
          <span>{{ $t('common').addDomains }}</span>
        </Card>
        <Card is-black>
          <p class="product-summary-card-content">{{ product.issuingTime }}</p>
          <span>{{ $t('common').issueTime }}</span>
        </Card>
        <Card is-black>
          <p class="product-summary-card-content compatible">{{ product.brwoserRecognition }}</p>
          <span>{{ $t('common').compatible }}</span>
        </Card>
      </div>
      <div class="product-details">
        <Card is-black class="flex flex-between">
          <ul class="details-left">
            <!-- <li class="details-item">
              <span class="details-label">{{ $t('common').price}}</span>
              <span class="details-value">{{ $t('common').currencySymbol + (product.annual) }}</span>
            </li> -->
            <li class="details-item">
              <span class="details-label">{{ $t('common').brand}}</span>
              <span class="details-value">{{ product.subBrand }}</span>
            </li>
            <li class="details-item">
              <span class="details-label">{{ $t('common').validity }}</span>
              <span class="details-value">{{ product.validityYears + $t('common').year }}</span>
            </li>
            <li class="details-item">
              <span class="details-label">{{ $t('common').secures }}</span>
              <span class="details-value">{{ product.secures }}</span>
            </li>
            <li class="details-item">
              <!-- Compatible w/ -->
              <span class="details-label">{{ $t('common').compatible }}</span>
              <span class="details-value">{{ product.compatible }}</span>
            </li>
          </ul>
          <ul class="details-center">
            <li class="details-item">
              <span class="details-label">{{ $t('common').publicKeyAlgorithm }}</span>
              <span class="details-value">{{ product.publicKeyAlgorithm }}</span>
            </li>
            <li class="details-item">
              <span class="details-label">{{ $t('common').symmetricEncryption }}</span>
              <span class="details-value">{{ product.symmetricEncryption }}</span>
            </li>
            <li class="details-item">
              <span class="details-label">{{ $t('common').publicKeyLength }}</span>
              <span class="details-value">{{ product.publicKeyLength }}</span>
            </li>
            <li class="details-item">
              <span class="details-label">{{ $t('common').rootCa }}</span>
              <span class="details-value">{{ product.rootCa }}</span>
            </li>
          </ul>
          <ul v-if="!/document/.test(product.sslType)" class="details-right">
            <li class="details-item">
              <!-- Money-back guarantee -->
              <span class="details-label">{{ $t('common').refundSince }}</span>
              <span class="details-value">{{ product.moneyBackGuarantee }}</span>
            </li>
            <li class="details-item">
              <span class="details-label">{{ $t('common').reIssue }}</span>
              <span class="details-value">{{ product.certificateReissue }}</span>
            </li>
            <li class="details-item">
              <span class="details-label">{{ $t('common').supportMulti }}</span>
              <span class="details-value">YES</span>
            </li>
            <li v-if="product.sslType === 'ssl'" class="details-item">
              <span class="details-label">{{ $t('common').warranty }}</span>
              <span class="details-value">{{ product.warranty }}</span>
            </li>
            <li v-if="/code/.test(product.sslType)" class="details-item">
              <span class="details-label">{{ $t('common').privatekeyontoken }}</span>
              <span class="details-value">{{ product.privateKeyOnToken === 'Y' ? 'YES' : 'NO' }}</span>
            </li>
            <li v-if="/email/.test(product.sslType)" class="details-item">
              <span class="details-label">{{ $t('common').warranty }}</span>
              <span class="details-value">{{ product.warranty ? product.warranty : 'N/A' }}</span>
            </li>
          </ul>
          <!-- document signing -->
          <ul v-if="/document/.test(product.sslType)" class="details-right">
            <li class="details-item">
              <span class="details-label">{{ $t('product').details.emailSigning }}</span>
              <span class="details-value">{{ product.emailSigning === 'Y' ? 'YES' : 'NO' }}</span>
            </li>
            <li class="details-item">
              <span class="details-label">{{ $t('product').details.emailEncryption }}</span>
              <span class="details-value">{{ product.emailEncryption === 'Y' ? 'YES' : 'NO' }}</span>
            </li>
            <li class="details-item">
              <span class="details-label">{{ $t('product').details.documentSigning }}</span>
              <span class="details-value">{{ product.documentSigning === 'Y' ? 'YES' : 'NO' }}</span>
            </li>
            <li class="details-item">
              <!-- Money-back guarantee -->
              <span class="details-label">{{ $t('common').refundSince }}</span>
              <span class="details-value">{{ product.moneyBackGuarantee }}</span>
            </li>
          </ul>
        </Card>
      </div>
      <div v-if="product.premiumFeaturesDescription" class="recommend-use">
        <CSection
          class="recomment-section"
          :title="$t('product').premium.title.replace('$brand', product.subBrand)">
          {{ product.premiumFeaturesDescription }}
        </CSection>
      </div>
      <div v-if="product.recommendedUse && product.sslType === 'ssl'" class="recommend-use">
        <CSection
          v-for="(recommend, i) in $t('product').recommendUse"
          :key="`product-recomment-item-${i}`"
          class="recomment-section"
          :title="recommend.title.replace('$brand', product.subBrand)">
          <p
            v-for="(item, j) in toArr(product.recommendedUse)"
            :key="`product-recomment-item-${i}-p-${j}`"
          >{{ item }}</p>
        </CSection>
      </div>
      <!-- Product preview order -->
      <div v-if="product.sslType === 'ssl'" class="preview-order-container">
        <OrderPreview :product="product"/>
      </div>
      <!-- S -->
      <CodeProduct v-else :product="product"/>
      <TlsMarkComponent v-if="product.sslType !== 'ssl' && product" :product="product"/>
      <!-- :src="$t('product').browerPreview[productBaseType]['coverImgUrl']"> -->
      <BrowserPreview
        v-if="/dv|ev|ov/.test(product.trustLevel) && brand"
        :title="$t('product').browerPreview[productBaseType]['title']"
        :src="brand ? genImgUrl(product, productBaseType, brand) : ''">
        <!-- {{ product.sslType }} -->
        <p
          v-for="(item, index) in product.certificateInBrowserDescription"
          :key="`product-browser-preview-summary-item-${index}`">
          {{ item }}
        </p>
      </BrowserPreview>
      <Faq />
      <CodeSigningTable v-if="product && /code/.test(product.sslType)" :product="product"/>
      <!-- reasons-to-buy -->
      <Jumbotron :title="$t('nbrand').reasonsToBuyTitle" class="reasons-to-buy">
        <ul>
          <li
            v-for="(item, index) in $t('nbrand').reasonsToBuy"
            :key="`reasons-to-buy-${index}`"
          >
            <b>{{ item.title }}</b>
            <p>{{ item.content }}</p>
          </li>
        </ul>
        <el-row :gutter="100" class="try-it-30d">
          <el-col :xs="24" :md="12" :sm="24">
            <ul>
              <li
                v-for="(item, index) in $t('nbrand').tryIt30d"
                :key="`brand-try-it-30d-${index}`"
              >
                <h4>{{ item.title }}</h4>
                <p>{{ item.content }}</p>
              </li>
            </ul>
          </el-col>
          <el-col :xs="0" :md="3" :sm="0">&nbsp;</el-col>
          <el-col :xs="0" :md="8" :sm="8">
            <img src="/images/30-day-money-back-guarantee-84a909a56545190336464fd7301190e140ca1466f33e46003e255b0e55ebaf25.svg" alt="">
          </el-col>
        </el-row>
      </Jumbotron>
      <!-- <Jumbotron class="satisfaction">{{ $t('nbrand').about }}</Jumbotron> -->
    </div>
  </ContainerCard>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import Grade from '@/components/Grade.vue'
import Signatures from '@/components/Signatures.vue'
import OrderPreview from '@/components/tls/OrderPreview.vue'
import BrowserPreview from '@/components/tls/BrowserPreview.vue'
import CodeSigningTable from '@/components/tls/CodeSigningTable.vue'
import CodeProduct from '@/components/CodeProducts.vue'
import TlsMarkComponent from '@/components/TlsMarkComponent.vue'
import Faq from '@/components/Faq.vue'
import { getProducts, getBrands } from '@/api/product'
import { getProductType } from '@/utils'
import { formatProductName } from '@/utils/format'

function getType (sslType) {
  if (!sslType) return
  const baseType = ['dv', 'ev', 'ov']
  return baseType.find(item => {
    const reg = RegExp(item)
    return reg.test(sslType)
  })
}

function toArr (content) {
  return Array.isArray(content) ? content : [content]
}

const signatures = {
  geotrust: {
    coverImgUrl: '/images/geotrustsiteseal.svg',
    link: ''
  },
  globalsign: {
    coverImgUrl: '/images/globalsign-site-seal.svg',
    link: ''
  },
  rapidssl: {
    coverImgUrl: '/images/rapidsslsiteseal.svg',
    link: ''
  },
  sectigo: {
    coverImgUrl: '/images/sectigositeseal.svg',
    link: ''
  },
  thawte: {
    coverImgUrl: '/images/thawtesiteseal.svg',
    link: ''
  },
  digicert: {
    coverImgUrl: '/images/digicertsiteseal.svg',
    link: ''
  },
  norton: {
    coverImgUrl: '/images/nortonsiteseal.svg',
    link: ''
  }
}

export default {
  name: 'Product',
  metaInfo () {
    return {
      title: this.product.productName
    }
  },
  components: {
    Faq,
    Grade,
    Signatures,
    CodeProduct,
    OrderPreview,
    BrowserPreview,
    CodeSigningTable,
    TlsMarkComponent
  },
  props: {
    asyncProduct: Object
  },
  setup (prop, ctx) {
    const { root } = ctx
    const { $route } = root
    const product = ref(prop.asyncProduct)
    const productBaseType = ref('')
    const brand = ref()
    // const
    async function reqBrands(product) {
      const brands = await getBrands(root.$i18n.locale)
      brand.value = brands.find(item => {
        return product.alias.indexOf(item.alias) > -1
      })
    }

    // 获取产品
    async function reqProducts (val = root.$i18n.locale) {
      if (product.value) {
        productBaseType.value = getType(product.value.trustLevel)
        reqBrands(product.value)
        return
      }
      getProducts(val).then(res => {
        product.value = res.find(item => item.alias === $route.params.alias)
        if (!product.value) return
        productBaseType.value = getType(product.value.trustLevel)
      })
    }

    // 签章图片
    const signaturesCoverImgUrl = computed(() => {
      if (!product.value) return
      const subBrand = product.value.subBrand
      if (!subBrand) return
      return signatures[subBrand] && signatures[subBrand].coverImgUrl // eslint-disable-line
    })

    const genImgUrl = (product, type, brand) => {
      let src = root.$t('product').browerPreview[type]['coverImgUrl']
      // let src = brand
      src = brand.preview[type]
      if (/code/.test(product.sslType + '')) {
        src = '/images/preview-code.png'
      }
      return src
    }

    watch(() => root.$i18n.locale, (val) => {
      reqProducts(val)
    }, { immediate: true })
    return {
      toArr,
      brand,
      product,
      genImgUrl,
      signatures,
      getProductType,
      productBaseType,
      formatProductName,
      signaturesCoverImgUrl
    }
  }
}

// async function reqProducts (locale, alias) {
//   const products = await getProducts(locale)
//   const product = products.find(item => item.alias === alias)
//   let productBaseType = null
//   if (product) {
//     productBaseType = getType(product.trustLevel)
//   }
//   return {
//     product,
//     productBaseType
//   }
// }
</script>

<style lang="less" scoped>
.product {
  padding-top: 24px;
}
.product-summary {
  margin-bottom: 24px;
}
.summary-title {
  font-weight: 300;
  color: @theme-color;
  margin-bottom: 24px;
}
.summary-content {
  line-height: 1.5em;
  font-size: 16px;
  font-weight: 500;
}
.product-summary-card {
  & /deep/ .card {
    width: calc(20% - 8px);
    margin: 0 24px 24px 0;
    text-align: center;
    height: 113px;
    img {
      width: 100%;
      margin-bottom: 12px;
    }
  }
  & /deep/ .card:last-child {
    margin-right: 0;
  }
  .ri-close-fill {
    display: block;
    font-size: 36px;
    margin-bottom: 12px;
    color: @text-color-l;
  }
}
.signature-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.product-summary-card-content {
  font-size: 26px;
  margin-bottom: 12px;
  &.compatible {
    margin-bottom: 16px;
  }
}
.product-details {
  margin-bottom: 48px;
  & /deep/ .card {
    max-width: 100%;
  }
}
.details-center,
.details-left,
.details-right {
  flex: 1;
  padding: 12px;
}
.details-center {
  border-left: @border;
  border-right: @border;
}
.details-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.details-item:last-child {
  margin-bottom: 0;
}
.details-label {
  color: @text-color-l;
}
.details-value {
  font-weight: 500;
}
.recomment-section {
  margin-bottom: 48px;
}

.satisfaction {
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  line-height: 1.5em;
  color: @text-color-l;
  border: @border;
  background-color: fade(@theme-color, 3);
  padding: 24px 0;
  & /deep/ .container {
    margin-top: 0;
  }
}

.reasons-to-buy {
  line-height: 1.5em;
  img {
    width: 100%;
  }
  li {
    padding-left: 72px;
    position: relative;
    &::before {
      content: "1";
      width: 48px;
      height: 48px;
      border: 1px solid @theme-color;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 36px;
      color: @theme-color;
      line-height: 50px;
      text-align: center;
    }
    p {
      margin-top: 12px;
    }
  }
  li ~ li {
    margin-top: 48px;
  }
  
  .Loop(@index) when(@index <= 5){
    li:nth-child(@{index}){
        &::before {
          content: "@{index}";
        }
    }
    .Loop(@index + 1)
  }
  .Loop(0);
  
  // h4 {
  //   font-family: 'Indie Flower', cursive;
  // }
}
.try-it-30d {
  margin-top: 48px;
  li {
    padding-left: 0;
    &::before {
      display: none;
    }
  }
  h4 {
    font-size: 18px;
    font-weight: bold;
    color: @theme-color;
  }
}

@media screen and (max-width: 1400px){
  .details-item {
    display: block;
    span {
      display: block;
    }
    span:first-child {
      margin-bottom: 6px;
    }
  }
}
@media screen and (max-width: 768px){
  .product-details {
    & /deep/ .card.flex {
      display: block;
    }
  }
  .summary-title.flex {
    flex-direction: column;
    align-items: flex-start;
    & > span {
      margin-bottom: 24px;
    }
  }
  .details-center {
    border-left: unset;
    border-right: unset;
  }
  .details-item {
    display: flex;
  }
  .details-item:last-child {
    margin-bottom: 15px;
  }
  .details-center,
  .details-left,
  .details-right {
    padding: 0 12px;
  }
  .details-right .details-item:last-child {
    margin-bottom: 0;
  }
  .product-summary-card {
    &.flex {
      flex-direction: column;
    }
    & /deep/ .card {
      width: 100%;
      margin-right: 0;
    }
  }
  // .recomment-section {
  //   margin-bottom: 48px;
  // }
  .preview-order-container {
    background-color: @bg-color;
    margin: 0 -24px;
  }
}
</style>
