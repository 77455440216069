<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-04-28 13:58:23
-->
<template>
  <div class="conatiner-header" :class="{ 'container-header-line': line !== undefined }">
    <slot>
      <h1 class="conatiner-header-title">{{ title }}</h1>
      <h4 class="conatiner-header-subtitle">{{ subtitle }}</h4>
      <p
        v-for="(item, index) in summary"
        :key="`container-header-summary-${index}`"
        class="conatiner-header-summary">
        {{ item }}
      </p>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'ContainerHeader',
  props: {
    title: String,
    subtitle: String,
    summary: Array,
    line: [String, undefined]
  }
}
</script>

<style lang="less" scoped>
.conatiner-header-subtitle {
  margin: 24px 0;
  & ~ p {
    margin-bottom: 24px;
    color: @text-color-l;
    line-height: 1.5em;
  }
}
.container-header-line {
  border-bottom: @border;
  margin-bottom: 24px;
}
</style>
