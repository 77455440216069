/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-05-05 09:45:08
 */
// import {
//   Row,
//   Col,
//   Table,
//   Select,
//   Option,
//   TableColumn,
//   Popover
// } from 'element-ui'
import Vue from 'vue'
import Card from './Card.vue'
import CImg from './CImg.vue'
import Banner from './Banner.vue'
import Logo from './Logo.vue'
import Product from './Product.vue'
import CButton from './CButton.vue'
import CSection from './CSection.vue'
import MobileTable from './MobileTable.vue'
import ArticleMedia from './ArticleMedia.vue'
import ContainerCard from './ContainerCard.vue'
import MarkContainer from './MarkContainer.vue'
import ContainerHeader from './ContainerHeader.vue'
import Jumbotron from './Jumbotron.vue'
import CountrySelect from './CountrySelect.vue'
import KeyTextarea from './KeyTextarea.vue'
// Layout
import CardGroup from '@/layouts/CardGroup.vue'
import BetweenArticle from '@/layouts/BetweenArticle.vue'
// Vendor UI
import ElementUI from 'element-ui'

Vue.use(ElementUI)

Vue.component(Card.name, Card)
Vue.component(CImg.name, CImg)
Vue.component(Banner.name, Banner)
Vue.component(Logo.name, Logo)
Vue.component(CardGroup.name, CardGroup)
// Vue.component(Row.name, Row)
// Vue.component(Col.name, Col)
// Vue.component(Table.name, Table)
Vue.component(Product.name, Product)
// Vue.component(Option.name, Option)
// Vue.component(Select.name, Select)
// Vue.component(Popover.name, Popover)
Vue.component(CountrySelect.name, CountrySelect)
Vue.component(CSection.name, CSection)
Vue.component(CButton.name, CButton)
Vue.component(Jumbotron.name, Jumbotron)
Vue.component(KeyTextarea.name, KeyTextarea)
// Vue.component(TableColumn.name, TableColumn)
Vue.component(MobileTable.name, MobileTable)
Vue.component(ArticleMedia.name, ArticleMedia)
Vue.component(MarkContainer.name, MarkContainer)
Vue.component(ContainerCard.name, ContainerCard)
Vue.component(ContainerHeader.name, ContainerHeader)
Vue.component(BetweenArticle.name, BetweenArticle)