export default {
  "matchCert": {
    "pageTitle": "Certificate Key Matcher",
    "title": "Certificate Key Matcher",
    "subtitle": "",
    "btn": "Match Now",
    "placeholder": [
      "Certificatr paste below or browse to upload",
      "CSR paste below or browse to upload ",
      "Private Key paste below or browse to upload "
    ],
    "radios": [
      'Cert Match Private Key',
      'CSR Match Private Key',
      'Cert Match CSR'
    ],
    "summary": [
      "You can use this Certificate Key Matcher to check whether a private key matches a certificate or whether a certificate matches a certificate signing request (CSR)",
      "All actions are done by client side, don't worry about the private key, no one will know it"
    ]
  }
}
