export default {
  x509: {
    basic: {
      title: '基础信息'
    },
    subject: {
      title: '主体信息'
    },
    issuer: {
      title: '颁发者信息'
    },
    from: {
      title: '证书来源',
      type: '来源类型',
      from: '来源',
      scan: '扫描',
      upload: '上传'
    },
    ellipsis: {
      expand: '折叠',
      more: '更多'
    },
    extension: {
      title: '扩展',
      name: '名称',
      important: '关键'
    },
    policy: {
      id: '策略 ID#',
      adorn: '策略修饰 ID#'
    },
    publicKey: {
      title: '公钥信息',
      valueLabel: '公钥'
    },
    thumbprints: {
      title: '指纹'
    },
    serialNumber: '序列号',
    version: '版本',
    validity: '有效期',
    notBefore: '颁发时间',
    notAfter: '到期时间',
    dnsName: 'DNS 名称',
    method: '方法 #',
    usage: '用途#',
    use: '使用',
    signature: '签名算法',
    algorithm: '算法',
    curve: '秘钥曲线',
    value: '值',
    ca: '证书颁发机构',
    y: '是',
    n: '否'
  }
}
