import affiliate from './affiliate_zh'
import reseller from './reseller_zh'
import member from './member_zh'
import enterprise from './enterprise_zh'

export default {
  partner: {
    member,
    reseller,
    affiliate,
    enterprise,
    // 合作伙伴导航栏信息
    navbar: {
      title: 'Certbase 合作伙伴网络',
      options: [
        { label: '大客户计划', link: '/partner/enterprise' },
        { label: '经销商/代理商计划', link: '/partner/reseller' },
        { label: '联盟推广计划', link: '/partner/affiliate' },
        { label: '会员折扣计划', link: '/partner/member' }
      ]
    },
    contact: {
      title: '建立在信任基础上的伙伴关系网络',
      link: '',
      linkText: '在线客服',
      options: [
        { label: '电话', link: 'tel:400-128-0988', value: '400-128-0988' },
        { label: '手机', link: 'tel:177-0811-9188', value: '177-0811-9188' },
        { label: '邮箱', link: 'mailto:support@certbase.com', value: 'support@certbase.com' },
      ]
    }
  }
}
