/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-05-05 17:18:55
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-05-09 16:35:50
 */
import ScrollReveal from 'scrollreveal'
const scrollReveal = ScrollReveal()

export const scrollMixin = {
  data() {
    return {
      scrollAnimateList: []
    }
  },
  methods: {
    scrollAnimate() {
      if (!this.scrollAnimateList) return
      const defaultOptions = {
        delay: 200,
        distance: '50px',
        reset: true
      }
      this.scrollAnimateList.forEach(item => {
        const options = item.options || {}
        scrollReveal
          .reveal(item.el, { ...defaultOptions, ...options })
      })
    }
  },
  mounted() {
    this.scrollAnimate()
  }
}
