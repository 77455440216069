export default {
  keyTextarea: {
    textarea: [
      'You can click the textarea to paste',
      '',
      'You can drag the file here or ',
      'click here to upload',
      'uploaded:'
    ],
    place: 'Paste PEM format CSR Below'
  }
}
